import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";

import { RightArrow } from "traec-react/utils/entities";

import { getASOTracker } from "AppSrc/project/utils";
import MetricCategories from "./metricCategories";

class ProjectTemplateMetrics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTemplate: null
    };

    this.requiredFetches = [
      new Traec.Fetch("project_tracker", "list"),
      new Traec.Fetch("tracker", "list", { onlyTemplates: true })
    ];

    // Bindings for this
    this.setTemplate = this.setTemplate.bind(this);
  }

  /**********************
    COMPONENT METHODS
    **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  /**********************
    MENU METHODS
    **********************/

  setTemplate(e) {
    e.preventDefault();
    this.setState({ selectedTemplate: e.target.value });
  }

  /**********************
    RENDER METHODS
    **********************/

  company_name() {
    let { company } = this.props;
    if (!company) {
      return "";
    }
    return <Link to={`/company/${company.get("uid")}`}>{company.get("name")}</Link>;
  }

  render_template_select() {
    let { templates } = this.props;
    let options = templates.toList().map((tracker, i) => (
      <option key={i} value={tracker.get("uid")}>
        {tracker.getInPath("project.name")}
      </option>
    ));
    options = options.unshift(
      <option key={-1} value={""}>
        Select a template:
      </option>
    );
    return (
      <select className={`custom-select col-sm-12`} onChange={this.setTemplate} defaultValue={""}>
        {options}
      </select>
    );
  }

  render_template_tree() {
    let { templates, refs, commitId, trackerId } = this.props;
    let { selectedTemplate } = this.state;
    if (!selectedTemplate) {
      return null;
    }

    // Get the actual template tracker root_ref
    let templateRefId = templates.getInPath(`${selectedTemplate}.root_master`);
    let templateRef = refs.getInPath(`${templateRefId}`);
    let templateCommitId = templateRef.getInPath(`latest_commit.uid`);
    let rootTreeId = templateRef.getInPath(`latest_commit.tree_root.uid`);

    return (
      <MetricCategories
        cref={templateRef}
        commitId={templateCommitId}
        rootTreeId={rootTreeId}
        copyToCommit={{ trackerId, commitId }}
        sortOrder={true}
      />
    );
  }

  render() {
    const { project, tracker } = this.props;
    if (!project || !tracker) {
      return "";
    }
    return (
      <React.Fragment>
        <h3>Add Metrics from Template</h3>
        <p>
          {this.company_name()} <RightArrow /> {project.get("name")}
        </p>

        <div className="row">
          {/* Render the template tree*/}
          <div className="col-sm-6">
            <h5>Template Metrics</h5>

            {/* Dropdown for selecting Template to pull from */}
            {this.render_template_select()}

            {/* Render the metric tree from the template */}
            {this.render_template_tree()}
          </div>
          {/* Render the current Metric Tree*/}
          <div className="col-sm-6">
            <h5>Current Metrics</h5>

            <MetricCategories
              cref={this.props.cref}
              commitId={this.props.commitId}
              rootTreeId={this.props.rootTreeId}
              sortOrder={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);
  let project = state.getInPath(`entities.projects.byId.${projectId}`);
  let company = project ? project.get("company") : null;

  // Try to get a Tracker for this Project (compatability with Track API)
  let trackerId = getASOTracker(project);
  let tracker = state.getInPath(`entities.trackers.byId.${trackerId}`);

  // Get the work package (ref) that we should be on
  // Get the root branch
  let rootRefId = tracker ? tracker.get("root_master") : null;
  let rootRef = rootRefId ? state.getInPath(`entities.refs.byId.${rootRefId}`) : null;

  // Get the current ref from url parameters
  let cref = refId ? state.getInPath(`entities.refs.byId.${refId}`) : rootRef;
  let commit = cref ? cref.get("latest_commit") : null;

  // Get the root tree to start with
  let rootTree = commit ? commit.get("tree_root") : null;
  let rootTreeId = rootTree ? rootTree.get("uid") : null;

  // Get all of the trees that are children of the rootTree (edges)
  let commitId = commit ? commit.get("uid") : null;

  // Get the available templates for this user
  let trackers = state.getInPath(`entities.trackers.byId`);
  let templates = trackers ? trackers.filter(item => item.get("is_template")) : null;
  let refs = state.getInPath("entities.refs.byId");

  return { projectId, project, company, tracker, trackerId, cref, commitId, rootTree, rootTreeId, templates, refs };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTemplateMetrics);

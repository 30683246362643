import React from "react";
import Moment from "moment";
import Traec from "traec";
import BaseFormConnected from "traec-react/utils/form";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import { projectPermissionCheck } from "traec/utils/permissions/project";

export const counter = { row: 0 };

const patchFields = {
  startDate: { label: "Start of this period", value: "", inputType: "date", class: "col" },
  endDate: { label: "Start of next period", value: "", inputType: "date", class: "col", endRow: true }
};

export default class ReportingPeriodRow extends React.Component {
  constructor(props) {
    super(props);

    let { projectId, reportingPeriod } = props;
    let reportingPeriodId = reportingPeriod.get("uid");
    let fetch = new Traec.Fetch("project_reporting_periods", "patch", { projectId, reportingPeriodId });

    this.state = {
      showForm: false,
      formParams: fetch.params
    };

    this.deleteItem = this.deleteItem.bind(this);
    this.editItem = this.editItem.bind(this);
  }

  dropDownLinks() {
    let { projectId, reportingPeriod } = this.props;
    let isProjectAdmin = projectPermissionCheck(projectId, true, []);
    if (!isProjectAdmin) {
      return [];
    }
    let links = [{ name: "Edit", onClick: this.editItem }];

    if (!reportingPeriod.get("num_commits")) {
      links = links.concat([{ name: "Delete", onClick: this.deleteItem }]);
    }

    return links;
  }

  editItem(e) {
    e.preventDefault();
    let { projectId, reportingPeriod } = this.props;
    let reportingPeriodId = reportingPeriod.get("uid");
    let fetch = new Traec.Fetch("project_reporting_periods", "patch", { projectId, reportingPeriodId });
    //
    fetch.updateFetchParams({
      preFetchHook: data => {
        return data;
      }
    });
    this.setState({ formParams: fetch.params });
    // Set the initial fields to the start and end date
    patchFields.startDate.value = reportingPeriod.getInPath("startDate");
    patchFields.endDate.value = reportingPeriod.getInPath("endDate");
    // Toggle form vis
    fetch.toggleForm();
  }

  deleteItem(e) {
    e.preventDefault();
    let { projectId, reportingPeriod } = this.props;
    let reportingPeriodId = reportingPeriod.get("uid");
    let fetch = new Traec.Fetch("project_reporting_periods", "delete", { projectId, reportingPeriodId });
    fetch.updateFetchParams({
      postSuccessHook: e => {
        location.reload();
      }
    });
    fetch.dispatch();
  }

  dateString(key) {
    let { reportingPeriod } = this.props;
    return Moment(reportingPeriod.get(key)).format("Do MMM YY") || "undefined";
  }

  reportingperiod_string() {
    let { reportingPeriod } = this.props;
    let periodString = reportingPeriod
      ? `${Moment(reportingPeriod.get("startDate")).format("Do MMM YY")} to ${Moment(reportingPeriod.get("endDate"))
          .add(-1, "days")
          .format("Do MMM YY")}`
      : null;
    if (!periodString) {
      periodString = " ";
    }
    return periodString;
  }

  render() {
    let { reportingPeriod } = this.props;

    let rowNum = counter.row++;
    let styleObj = { backgroundColor: (rowNum + 1) % 2 ? "#ddd" : "" };
    return (
      <React.Fragment>
        <div className="row" style={styleObj}>
          <div className="col-sm-2">{this.dateString("startDate")}</div>
          <div className="col-sm-2">
            {Moment(reportingPeriod.get("endDate"))
              .add(-1, "days")
              .format("Do MMM YY") || "undefined"}
          </div>
          <div className="col-sm-2 text-center">{reportingPeriod.get("num_commits")}</div>
          <div className="col-sm-5">{reportingPeriod.get("uid").substring(0, 8)}</div>
          <div className="col-sm-1">
            <BSBtnDropdown links={this.dropDownLinks()} />
          </div>
        </div>
        <div className="row">
          <BaseFormConnected params={this.state.formParams} fields={patchFields} />
        </div>
      </React.Fragment>
    );
  }
}

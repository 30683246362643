import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";
import { ErrorBoundary } from "traec-react/errors/handleError";

import { getProjectProps, loadConvFacts, BreadCrumb } from "AppSrc/project/utils";
import CFList from "./list";

import ConversionFactorSet from "AppSrc/project/details/convFactorSet";

const folderName = "conversion_factors";

class CFDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedBranches: false,
      fetchedConversionFactors: false
    };

    this.requiredFetches = [
      new Traec.Fetch("tracker_commit_convfactor", "list"),
      new Traec.Fetch("tracker_branch", "list")
    ];

    // action bindings
    this.addConvFactorBranch = this.addConvFactorBranch.bind(this);
    this.setUserConvFactorBranch = this.setUserConvFactorBranch.bind(this);
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  /*
    FETCH HANDLERS
    */

  getUrlParams() {
    const { cref, crefId } = this.props;
    if (!cref) {
      return {};
    }
    const trackerId = cref.get("tracker");
    const commitId = cref.get("latest_commit").get("uid");
    const refId = crefId;
    const rootTreeId = cref.getIn(["latest_commit", "tree_root", "uid"]);
    return { refId, commitId, trackerId, rootTreeId };
  }

  /*
    EVENT ACTIONS
    */

  addConvFactorBranch(e) {
    e.preventDefault();
    let { trackerId, refId, commitId, rootTreeId: treeId } = this.getUrlParams();
    let fetch = Traec.Fetch("tracker_ref_tree_branch", "post", { trackerId, refId, commitId, treeId });
    fetch.updateFetchParams({ body: { name: folderName } });
    fetch.dispatch();
  }

  setUserConvFactorBranch(e, isMaster = false) {
    e.preventDefault();
    let newHeadRefId = e.target.value;
    console.log("CHANGING CONVERSION FACTOR HEAD", newHeadRefId);
    const { trackerId, refId } = this.getUrlParams();
    let { convFactRef } = this.props;
    let branchId = convFactRef.getInPath("latest_commit.root_commit");
    let convFactRefId = convFactRef.get("uid");
    let fetch = new Traec.Fetch("tracker_ref_head", "put", { trackerId, refId: convFactRefId, branchId });
    fetch.updateFetchParams({
      body: {
        is_master: isMaster,
        target: { ref: newHeadRefId }
      },
      postSuccessHook: () => {
        location.reload();
      }
    });
    fetch.dispatch();
  }

  company_name() {
    let { company } = this.props;
    if (!company) {
      return "";
    }
    return <Link to={`/company/${company.get("uid")}`}>{company.get("name")}</Link>;
  }

  render_spinner() {
    //https://loading.io/css/
    return (
      <div className="text-center">
        <div className="lds-dual-ring" />
      </div>
    );
  }

  render_loading() {
    let { subBranches } = this.props;
    if (this.state.fetchedBranches && !subBranches) {
      return this.render_spinner();
    }
  }

  render_add_branch() {
    return (
      <React.Fragment>
        <p>
          No conversion factor branch was found for this project. Something may have gone wrong with your project setup.
          Please contact a site adminiator to resolve this.
        </p>
        {/*
            <button className={"btn btn-primary"} onClick={this.addConvFactorBranch}>
                Add Conversion Factor branch
            </button>
            */}
      </React.Fragment>
    );
  }

  render_branch_select_dropdown() {
    let { revisionRefs, convFactRef } = this.props;
    if (!revisionRefs) {
      return null;
    }

    const options = revisionRefs.map((cref, i) => (
      <option key={i} value={cref.get("uid")}>
        {cref.get("name")} ({cref.get("uid").substring(0, 8)})
      </option>
    ));
    return (
      <React.Fragment>
        <select
          className={`custom-select col-sm-3`}
          onChange={this.setUserConvFactorBranch}
          defaultValue={convFactRef.get("uid", "")}
        >
          {options}
        </select>
      </React.Fragment>
    );
  }

  render_conv_factors() {
    let { trackerId, cref, commitId, convFactorMap, projectId, convFactorGroup } = this.props;

    // Return nothing if we don't have a conversion factor map object
    if (!convFactorMap) {
      return null;
    }

    return (
      <ErrorBoundary>
        <p>
          Currently using conversion factors from: <b>{convFactorGroup}</b>
        </p>

        <ConversionFactorSet
          trackerId={trackerId}
          refId={cref ? cref.get("uid") : null}
          value={cref ? cref.getInPath("latest_commit.meta_json.conversion_factor_group") : ""}
        />

        <hr />
        <h5>Locally overridden conversion factors:</h5>
        <CFList
          projectId={projectId}
          trackerId={trackerId}
          commitId={commitId}
          convFactMap={convFactorMap}
          isGlobal={false}
          hideGlobalColumn={true}
        />

        <hr style={{ marginTop: "3rem" }} />
        <h5>
          Global Conversion Factors from <b>{convFactorGroup}</b>
        </h5>
        <CFList
          projectId={projectId}
          trackerId={trackerId}
          commitId={commitId}
          convFactMap={convFactorMap}
          isGlobal={true}
          hideSetupMenu={true}
          hideGlobalColumn={true}
          emptyHelp={<p className="text-center">No global conversion factors in use</p>}
        />
      </ErrorBoundary>
    );
  }

  render() {
    const { company, project, cref, tracker } = this.props;
    if (!project || !tracker) {
      return null;
    }
    return (
      <React.Fragment>
        <h3>Conversion Factors</h3>
        <BreadCrumb company={company} project={project} cref={cref} isRootRef={true} />

        {/* Render the conversion factors */}
        {this.render_conv_factors()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //const { _projectId } = ownProps.match.params;
  const { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, tracker, trackerId, cref, crefId } = getProjectProps(state, projectId, refId);

  // Get the conversion factor branch (a "hidden" root branch of the tracker)
  let commitId = cref ? cref.getInPath("latest_commit.uid") : null;
  let convFactorMap = loadConvFacts(state, commitId) || {};
  let convFactorGroup = cref ? cref.getInPath("latest_commit.meta_json.conversion_factor_group") : null;

  // TODO: Get alternative possible conversion factor groups

  return {
    projectId,
    project,
    company,
    tracker,
    trackerId,
    cref,
    crefId,
    commitId,
    convFactorMap,
    convFactorGroup
  };
};

export default connect(mapStateToProps)(CFDisplay);

import React from "react";
import ProjectMembers from "traec-react/members/projectMembers";
import { connect } from "react-redux";
import PendingSupplierRequests from "AppSrc/project/details/supplierRequest";
import Traec from "traec";

const ASOProjectMembers = props => {
  return (
    <React.Fragment>
      <ProjectMembers match={{ params: { projectId: props.projectId } }}>
        <PendingSupplierRequests project={props.project} />
      </ProjectMembers>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { projectId } = Traec.utils.getFullIds(state, ownProps.match.params);
  let project = state.getInPath(`entities.projects.byId.${projectId}`);
  return { projectId, project };
};

export default connect(mapStateToProps)(ASOProjectMembers);

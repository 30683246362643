import Traec from "traec";

export const combineScoreMetric = (state, scoreId) => {
  //console.log("Combining score", scoreId)
  let metricScore = state.getInPath(`entities.metricScores.byId.${scoreId}`);
  if (!metricScore) {
    return metricScore;
  }
  let baseMetric = state.getInPath(`entities.baseMetrics.byId.${metricScore.get("metric")}`);
  return baseMetric ? metricScore.set("metric", baseMetric) : metricScore;
};

export const combineTreeScore = (state, path, commitNodes) => {
  let treeId = commitNodes.getInPath(`byPath.${path}.uid`);
  let tree = state.getInPath(`entities.trees.byId.${treeId}`);

  if (!tree) {
    //console.log(`WARNING: No tree found at path: ${path}`)
    return null;
  }

  let subScorePaths = commitNodes.getInPath(`byPath.${path}.children.byType.metricScores`) || Traec.Im.Set();

  let subScoreIds = subScorePaths.map(scorePath => commitNodes.getInPath(`byPath.${scorePath}.uid`));
  if (subScoreIds.size > 1) {
    //console.log(`WARNING: Multiple metricscore objects found below tree at path: ${path}`);
  }
  let score = combineScoreMetric(state, subScoreIds.first());
  //console.log("Combining tree", treeId, ' and score', score ? score.toJS() : null)
  return tree.set("score", score);
};

export const sortTrees = trees => {
  trees.sort((a, b) => (a.getInPath("score.metric.name") || "").localeCompare(b.getInPath("score.metric.name") || ""));
};

export const combineNodeDescription = (state, commitNodes, rootPath, node) => {
  // Append description to a node
  let paths = commitNodes.getInPath(`byPath.${rootPath}.children.byType.descriptions`) || Traec.Im.Set();
  let ids = paths.map(path => commitNodes.getInPath(`byPath.${path}.uid`));
  // Get the first description (if multiple available)
  let id = ids ? ids.first() : null;
  // Append to the original node
  if (id) {
    node = node.set("description", state.getInPath(`entities.descriptions.byId.${id}`));
  }
  return node;
};

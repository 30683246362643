import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";

import ProjectHome from "./home";
import ProjectMetrics from "./metrics";
import ProjectTemplateMetrics from "AppSrc/project/metrics/template";
import WPCommits from "./commits";
import ConvFactorTable from "./convfacts";
import ProjectReport, { ProjectNoReport } from "./report";
import ProjectDetails from "AppSrc/project/details";
import ProjectIndicators from "./indicators/";
import WorkPackageDetails from "./wptree/details";

import { getProjectPermissions } from "traec/utils/permissions/project";

import ProjectIndicatorTarget from "AppSrc/project/indicators/indicatorTargets";
import ProjectApportionment from "AppSrc/project/apportionment";
import ASOProjectMembers from "AppSrc/project/members";
import EmailSettings from "traec-react/emails/settings";
import EmailReport from "traec-react/emails/report";
import RecipientEmailReport from "traec-react/emails/report/recipientEmailReport";

import { ErrorBoundary } from "traec-react/errors";
import { setNavBar } from "./navbar";

class ProjectRouterBase extends React.Component {
  constructor(props) {
    super(props);

    this.requiredFetches = [
      new Traec.Fetch("project_permission", "list"),
      new Traec.Fetch("project_tracker", "list"),
      new Traec.Fetch("tracker_branch", "list"),
      new Traec.Fetch("company", "read"),
      new Traec.Fetch("company", "list"),
      new Traec.Fetch("project", "read"),
      new Traec.Fetch("project", "list"),
      new Traec.Fetch("tracker_ref_all", "list"),
      new Traec.Fetch("tracker_commit_branch", "list")
    ];
  }

  componentDidMount() {
    //console.log("Project Router mounted", this.props)
    Traec.fetchRequiredFor(this);
    setNavBar(this.props);
  }

  componentDidUpdate() {
    //console.log("Project Router updated", this.props)
    Traec.fetchRequiredFor(this);
    setNavBar(this.props);
  }

  render() {
    return (
      <ErrorBoundary>
        <Switch>
          {/* there will only ever be one child in the Switch */}

          {/* Pages for Members, Invites and Auth */}
          <Route exact path={`/project/:_projectId/members`} component={ASOProjectMembers} />

          {/* Pages the Project Details */}
          <Route exact path={`/project/:_projectId/details`} component={ProjectDetails} />
          <Route exact path={`/project/:_projectId/wpack/:_refId/details`} component={WorkPackageDetails} />

          {/* Pages for Members, Invites and Auth */}
          <Route exact path={`/project/:_projectId/metrics`} component={ProjectMetrics} />
          <Route exact path={`/project/:_projectId/wpack/:_refId/metrics`} component={ProjectMetrics} />
          <Route
            exact
            path={`/project/:_projectId/wpack/:_refId/metrics/add_from_template`}
            component={ProjectTemplateMetrics}
          />
          <Route exact path={`/project/:_projectId/metrics/add_from_template`} component={ProjectTemplateMetrics} />

          {/* Pages for Performance Evaluations (commits) */}
          <Route exact path={`/project/:_projectId/evals`} component={WPCommits} />
          <Route exact path={`/project/:_projectId/wpack/:_refId/evals`} component={WPCommits} />

          {/* Pages for Performance Evaluations (commits) */}
          <Route exact path={`/project/:_projectId/indicators`} component={ProjectIndicators} />
          <Route exact path={`/project/:_projectId/wpack/:_refId/indicators`} component={ProjectIndicators} />

          {/* Repot page for generating pdf indicator/metric report  */}
          {/* <Route exact path={`/project/:_projectId/report`} component={ProjectReport} /> */}
          {/* Reporting input form for a particular ref/commit */}
          <Route exact path={`/project/:_projectId/wpack/:_refId/report`} component={ProjectReport} />
          <Route exact path={`/project/:_projectId/wpack/:_refId/report/:_commitId`} component={ProjectReport} />
          <Route exact path={`/project/:_projectId/wpack/:_refId/noreport`} component={ProjectNoReport} />

          {/* Pages for Conversion Factors and Indicators */}
          <Route exact path={`/project/:_projectId/indicators`} component={ProjectIndicators} />
          <Route exact path={`/project/:_projectId/conv`} component={ConvFactorTable} />
          <Route exact path={`/project/:_projectId/indicator/:indicatorId/target`} component={ProjectIndicatorTarget} />
          <Route exact path={`/project/:_projectId/wpack/:_refId/conv`} component={ConvFactorTable} />
          <Route
            exact
            path={`/project/:_projectId/wpack/:_refId/indicator/:indicatorId/target`}
            component={ProjectIndicatorTarget}
          />
          <Route exact path={`/project/:_projectId/wpack/:_refId/apportionment`} component={ProjectApportionment} />
          <Route
            exact
            path={`/project/:_projectId/wpack/:_refId/apportionment/:reportingPeriodId`}
            component={ProjectApportionment}
          />

          {/* Pages for email statistics */}
          <Route exact path={`/project/:_projectId/email/settings`} component={EmailSettings} />
          <Route exact path={`/project/:_projectId/email/report`} component={EmailReport} />
          <Route exact path={`/project/:_projectId/email/report/:recipientId`} component={RecipientEmailReport} />

          {/* Default render homepage if no path matched */}
          <Route exact path={`/project/:_projectId/wpack/:_refId`} component={ProjectHome} />
          <Route component={ProjectHome} />
        </Switch>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);
  let userPermissions = getProjectPermissions(state, projectId);
  return { projectId, refId, userPermissions };
};

const ProjectRouter = connect(mapStateToProps)(ProjectRouterBase);

export { ProjectRouter };

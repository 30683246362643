import React from "react";
import Traec from "traec";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { BSBtnDropdown, BSModal } from "traec-react/utils/bootstrap/";
import BaseFormConnected from "traec-react/utils/form";
import { counter } from "./index";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { ErrorBoundary } from "traec-react/errors";
import { workPackageFields } from "./form";

const deleteWorkPackage = (e, props) => {
  e.preventDefault();
  let { commitId, rootRefId: refId, trackerId, rootRef } = props;
  let refName = rootRef ? rootRef.get("name") : null;
  if (!trackerId | !refId | !commitId) {
    return null;
  }
  confirmDelete({
    text: `This will delete the Reporting Package: ${refName}.\n\n Are you sure you would like to proceed?`,
    onConfirm: () => {
      new Traec.Fetch("tracker_ref", "delete", { trackerId, refId, commitId }).dispatch();
    }
  });
};

function SubRefs(props) {
  let { projectId, tracker, cref, subRefs, showMenu, menuOnSelectedOnly } = props;
  if (!subRefs) {
    return null;
  }

  return subRefs
    .filter(i => i)
    .map((item, i) => (
      <WorkPackageRowConnected
        key={i}
        rootRef={item}
        projectId={projectId}
        tracker={tracker}
        cref={cref}
        showMenu={showMenu}
        menuOnSelectedOnly={menuOnSelectedOnly}
      />
    ));
}

function WPDropDownMenu(props) {
  let { showMenu, projectId, trackerId, rootRefId: refId, commitId, treeId, hideDelete } = props;
  if (!showMenu) {
    return null;
  }

  let modalId = `${refId}-modal`;

  // Get the fetch for adding a new sub-work package
  let fetch = new Traec.Fetch("tracker_ref_tree_branch", "post", {
    trackerId,
    refId,
    commitId,
    treeId,
    skip_categories: true
  });
  fetch.updateFetchParams({
    preFetchHook: body => {
      let _body = {
        ...body,
        ref_name: body.name || "master",
        from_commit: commitId
      };
      console.log("CREATING REF WITH PARAMETERS", _body);
      return _body;
    },
    postSuccessHook: () => {
      $(`#${modalId}`).modal("hide");
    }
  });

  let links = [
    {
      name: "Add a sub-Reporting Package",
      onClick: e => {
        $(`#${modalId}`).modal("show");
      }
    },
    {
      name: "Settings",
      linkTo: `/project/${projectId.substring(0, 8)}/wpack/${refId.substring(0, 8)}/details`
    }
  ];
  if (!hideDelete) {
    links = links.concat([{}, { name: "Delete", onClick: e => deleteWorkPackage(e, props) }]);
  }

  return (
    <div className="sidebar-dropdown-text col-sm-1 m-0 p-0">
      <BSBtnDropdown links={links} header={" "} />
      <BSModal
        id={modalId}
        title={"Add a Reporting Package"}
        body={
          <BaseFormConnected
            params={fetch.params}
            fields={workPackageFields}
            forceShowForm={true}
            hideUnderline={true}
          />
        }
      />
    </div>
  );
}

class WorkPackageRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  isCurrent() {
    let { cref, rootRef } = this.props;
    if (cref.get("uid") == rootRef.get("uid")) {
      return true;
    }
    return false;
  }

  isRoot() {
    let { rootRef, tracker } = this.props;
    return rootRef.get("uid") === tracker.get("root_master");
  }

  render() {
    /* Render the reporting packages from the ref only if the project id's match. 
        This is to solve an issue where creating a new project from a template would cause the RP's 
        from the template to appear randomly in the newly created project
    */

    //console.log("RENDERING WORK PACKAGE ROW")
    let {
      showMenu,
      projectId,
      rootRef,
      includeRoot = true,
      alternateBgColor = true,
      menuOnSelectedOnly = false
    } = this.props;
    let rootTreeName = rootRef.get("name");

    // Skip the special "conversion_factors" branch
    if (rootTreeName == "conversion_factors") {
      return "";
    }

    rootTreeName = this.isRoot() ? <b>Project Home</b> : rootTreeName;
    let bgColor = this.isCurrent() ? "bg-info" : "";
    let linkLoc = this.isRoot()
      ? `/project/${projectId.substring(0, 8)}`
      : `/project/${projectId.substring(0, 8)}/wpack/${rootRef.get("uid").substring(0, 8)}`;

    let rowNum = counter.row++;
    let styleObj = showMenu && alternateBgColor ? { backgroundColor: (rowNum + 1) % 2 && !bgColor ? "#ddd" : "" } : {};
    styleObj = {};

    let shouldRender = rootRef.get("project") === projectId;

    let doRender = true;
    if (!includeRoot && this.isRoot()) {
      doRender = false;
    }

    // Whether we should show the dropdown menu
    let _showMenu = showMenu;
    if (menuOnSelectedOnly) {
      _showMenu = this.isCurrent();
    }

    return (
      <ErrorBoundary>
        <div className={"ml-2"}>
          {shouldRender && doRender ? (
            <div className={`row m-0 p-0 ${bgColor}`} style={styleObj}>
              <div key={this.props.rootRef} className={`${showMenu ? "col-sm-11" : "col-sm-12"} m-0 p-0`}>
                <Link to={linkLoc}>
                  <p className={`m-0 p-0`}>{shouldRender ? rootTreeName : null}</p>
                </Link>
              </div>
              {_showMenu ? <WPDropDownMenu {...this.props} /> : null}
            </div>
          ) : null}

          <SubRefs {...this.props} />
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // Get the children on this row from the store
  let { projectId, tracker, rootRef } = ownProps;

  let rootRefId = rootRef.get("uid");
  let commitId = rootRef.get("latest_commit").get("uid");
  let trackerId = tracker.get("uid");
  let treeId = rootRef.getInPath("latest_commit.tree_root.uid");

  // There should not be multiple revisions of branches
  let subBranches = state.getInPath(`entities.commitBranches.commit.${commitId}.branch`) || Traec.Im.Map();
  let subRefs = [];
  for (let branch of subBranches.valueSeq()) {
    let branchRefs = branch.get("byId") || Traec.Im.Map();
    for (let commitBranch of branchRefs.valueSeq()) {
      let subRefId = commitBranch.getInPath("target.ref");
      if (subRefId != rootRefId) {
        subRefs.push(state.getInPath(`entities.refs.byId.${subRefId}`));
      }
    }
  }

  return { commitId, rootRefId, trackerId, treeId, subRefs, projectId };
};

const WorkPackageRowConnected = connect(mapStateToProps)(WorkPackageRow);
export default WorkPackageRowConnected;

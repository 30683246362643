import { getCommitStageStatus, getCommitStatus } from "AppSrc/project/commits/row";
import { ApproveRejectForm } from "AppSrc/project/commits/approveReject";
import { Tooltip } from "react-tippy";
import React from "react";
import { BSBtn } from "traec-react/utils/bootstrap";
import { isRequiredInPeriod } from "AppSrc/project/report/reportMetricRow";
import { reportingPeriodText } from "AppSrc/project/report/utils";
import Traec from "traec";
import { getScoreValuesByBaseMetric } from "./utils";
import Octicon from "react-octicon";

import { isMetaComplete } from "./index";
import { useState } from "react";

export const Submit = props => {
  let {
    commit,
    refId,
    currentReportingPeriod,
    userProjectPermissions,
    projectId,
    projectBaseDisciplineMap,
    projectDisciplines,
    metricScores,
    baseMetrics,
    scoreValues, // Map with scoreValues indexed by baseMetricId (bmScoreValues)
    disableInputs,
    inputErrors,
    postCommit,
    patchCommit,
    needsRevision,
    sectionsComplete,
    categoryTrees,
    revalidate,
    buttonText
  } = props;

  buttonText = buttonText || "Submit for Approval";
  let commitStatus = getCommitStatus(commit);
  let metaComplete = isMetaComplete(commit?.get("meta_json"));
  let [spinOcticon, setSpinOcticon] = useState(false);

  // Get if all of the sections are complete
  let report_layout = commit ? commit.getInPath("meta_json.report_layout") || "classic" : "classic";
  let includeSectionComplete = report_layout != "classic";
  let allSectionsComplete = includeSectionComplete ? sectionsComplete.every(i => i === true) : true;

  if (commitStatus && commitStatus.startsWith("Not for")) {
    return <BSBtn text="Reporting On Hold" onClick={null} disabled={true} />;
  }

  if (!getCommitStageStatus(commit) && !(commitStatus === "Requires Revision")) {
    let currentPeriodString = currentReportingPeriod ? reportingPeriodText(currentReportingPeriod) : "";
    return (
      <ApproveRejectForm
        currentPeriodString={currentPeriodString}
        commit={commit}
        crefId={refId}
        userPermission={userProjectPermissions}
        projectId={projectId}
        projectBaseDisciplineMap={projectBaseDisciplineMap}
        projectDisciplines={projectDisciplines}
      />
    );
  }

  if (disableInputs) {
    return null;
  }

  let scoreValuesByBaseMetric = getScoreValuesByBaseMetric(scoreValues);
  let hasAllRequired = isAllRequiredSubmitted(
    scoreValuesByBaseMetric,
    metricScores,
    baseMetrics,
    currentReportingPeriod
  );
  let sectionsWithErrors = (inputErrors || Traec.Im.Map()).toList().filter(i => i.size);

  // Validate scores that require equating to report meta_json
  if (hasAllRequired == true) {
    console.log("Validating fields marked to equal report meta-data");
    hasAllRequired = validateToReportMeta(scoreValuesByBaseMetric, metricScores, baseMetrics, commit);
    console.log(
      "Got validation against report meta-data",
      hasAllRequired !== true ? hasAllRequired.toJS() : hasAllRequired
    );
  }

  if (hasAllRequired !== true || sectionsWithErrors.size || !allSectionsComplete || !metaComplete) {
    console.log(
      "Blocking submit",
      Traec.Im.isImmutable(hasAllRequired) ? hasAllRequired.toJS() : hasAllRequired,
      sectionsWithErrors.size,
      allSectionsComplete
    );
    let errorText = !metaComplete ? (
      <ul className="tooltip-list">
        <li>Complete company information</li>
      </ul>
    ) : (
      getErrorText(hasAllRequired, inputErrors, sectionsComplete, categoryTrees, includeSectionComplete)
    );

    function handleValidateClick() {
      setSpinOcticon(true);
      revalidate();
      setTimeout(() => {
        setSpinOcticon(false);
      }, 2000);
    }
    return (
      <div className={"float-right"}>
        <Tooltip html={<div className={"text-left"}>{errorText}</div>} animateFill={false}>
          <BSBtn text={buttonText} disabled={true} noFloatRight={true} />
        </Tooltip>
        <Tooltip html={"Re-validate this form"} animateFill={false}>
          <Octicon
            name="sync"
            className="text-secondary ml-3"
            style={{ cursor: "pointer" }}
            onClick={handleValidateClick}
            spin={spinOcticon ? true : false}
          />
        </Tooltip>
      </div>
    );
  }
  return (
    <BSBtn
      text={buttonText}
      onClick={e => {
        needsRevision() ? patchCommit(e) : postCommit(e);
      }}
    />
  );
};

export const SubmitButton = ({ needsRevision, postCommit, patchCommit, buttonText }) => {
  let method = needsRevision() ? patchCommit : postCommit;
  return <BSBtn text={buttonText} onClick={method} />;
};

const requiredErrors = required => {
  return required.map((baseMetricName, i) => <li key={i}>{baseMetricName}</li>);
};

const getErrorText = (required, inputErrors, sectionsComplete, categoryTrees, includeSectionComplete) => {
  // Get a mapping from issue paths to real names
  let pathNameMap = categoryTrees
    ? categoryTrees.reduce((acc, cur) => acc.set(cur.get("_path"), cur.get("name")), Traec.Im.Map())
    : Traec.Im.Map();

  let sectionErrors = Traec.Im.List();

  if (sectionsComplete && sectionsComplete.size) {
    sectionErrors = sectionsComplete
      .map((complete, categoryPath, i) => {
        let categoryName = pathNameMap.get(categoryPath) || categoryPath;
        if (complete === true) {
          return null;
        } else {
          if (complete === false || required === true) {
            return includeSectionComplete ? <li key={i}>{categoryName} section not marked as complete</li> : null;
          } else {
            return (
              <React.Fragment key={i}>
                <li key={i}>{categoryName} section requires value for metrics:</li>
                <ul>{requiredErrors(complete)}</ul>
              </React.Fragment>
            );
          }
        }
      })
      .toList()
      .filter(i => i);
  }

  let _inputErrors = inputErrors
    ? inputErrors
        .valueSeq()
        .filter(i => Traec.Im.isImmutable(i) && i.get("name"))
        .map((item, i) => <div key={i}>{`${item.get("name")} was given a non-number value: ${item.get("value")}`}</div>)
    : Traec.Im.List();

  let _tooltip =
    sectionErrors.size || _inputErrors.size ? (
      <React.Fragment>
        {sectionErrors}
        {_inputErrors}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <p>Please correct the following data before submitting the report</p>
        {required?.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </React.Fragment>
    );

  return <ul className="tooltip-list">{_tooltip}</ul>;
};

const metricRequiredAndHasValue = (score, scoreValuesByBaseMetric, currentReportingPeriod, baseMetrics) => {
  let baseMetricId = score.getInPath("metric.uid") || score.get("metric");
  let isRequired = isRequiredInPeriod(score, currentReportingPeriod);
  let noReport = scoreValuesByBaseMetric.getIn([baseMetricId, "meta_json", "noReport"]);
  let value = scoreValuesByBaseMetric.getIn([baseMetricId, "value"]);
  let valueId = scoreValuesByBaseMetric.getIn([baseMetricId, "valueId"]);
  let hasValueOrValueId = noReport || (value !== null && value !== undefined);
  return isRequired && !hasValueOrValueId ? baseMetrics.getIn([baseMetricId, "name"]) : true;
};

export const isAllRequiredSubmitted = (scoreValuesByBaseMetric, metricScores, baseMetrics, currentReportingPeriod) => {
  if (!scoreValuesByBaseMetric || !metricScores || !baseMetrics) {
    return null;
  }

  // Check each of the metricScores and see if they have a value if required
  let isRequiredAndHasValue = metricScores
    .toList()
    .map(score => metricRequiredAndHasValue(score, scoreValuesByBaseMetric, currentReportingPeriod, baseMetrics));
  isRequiredAndHasValue = isRequiredAndHasValue.filter(i => i !== true);

  return isRequiredAndHasValue.size ? isRequiredAndHasValue : true;
};

const equalsReportMeta = (score, scoreValuesByBaseMetric, baseMetrics, commit) => {
  let baseMetricId = score.getInPath("metric.uid") || score.get("metric");
  let field = score.getInPath("meta_json.equals_report_meta");
  if (!field) {
    return true;
  }

  let report_meta_value = parseFloat(commit.getIn([`meta_json`, field]));
  if (isNaN(report_meta_value)) {
    return true;
  }

  let value = scoreValuesByBaseMetric.getIn([baseMetricId, "value"]);
  if (isNaN(parseFloat(value))) {
    return true;
  }
  return (
    value == report_meta_value ||
    baseMetrics.getIn([baseMetricId, "name"]) +
      ` must match value provided in Company Information ${report_meta_value}, not: ${value}`
  );
};

const validateToReportMeta = (scoreValuesByBaseMetric, metricScores, baseMetrics, commit) => {
  let isEqualReportMeta = metricScores
    .toList()
    .map(score => equalsReportMeta(score, scoreValuesByBaseMetric, baseMetrics, commit))
    .filter(i => i !== true);

  return isEqualReportMeta.size ? isEqualReportMeta : true;
};

import React, { useEffect } from "react";
import Traec from "traec";
import { BreadCrumb, getProjectProps } from "AppSrc/project/utils";
import { connect } from "react-redux";
import ClientPortions from "AppSrc/project/apportionment/clientPortions";
import ProjectInviteRequestPanel from "AppSrc/project/apportionment/projectInviteRequest";
import { SubmitToClients } from "AppSrc/project/apportionment/submitToClients";
import AcceptedRequestPanel from "AppSrc/project/apportionment/acceptedRequests";
import { SubmittedReports } from "AppSrc/project/apportionment/submittedReports";
import { ReportingPeriodsDropdown } from "AppSrc/project/apportionment/reportingPeriodsDropdown";
import { getReportingPeriod } from "AppSrc/project/apportionment/utils";

const ProjectApportionment = props => {
  if (!props.project) {
    return null;
  }

  const [state, setState] = React.useState({
    reportingPeriodId: null
  });

  useEffect(() => {
    Traec.fetchRequiredFor({
      props: { ...props, include_commit_results: false, refId: null },
      requiredFetches: [
        new Traec.Fetch("project_reporting_periods", "list"),
        new Traec.Fetch("project_supplier_sent", "list")
      ]
    });
  });

  const updateReportingPeriodId = (e, reportingPeriodId) => {
    e.preventDefault();
    setState({ ...state, reportingPeriodId });
  };

  if (!props.project.getInPath("meta_json.apportionment")) {
    return (
      <div className="p-3 m-3">
        <p>
          This project has not apportionment enabled. Please contact your site admin, if you think it should change.
        </p>
      </div>
    );
  } else {
    let currentReportingPeriodId = state.reportingPeriodId ? state.reportingPeriodId : props.currentReportingPeriodId;
    let currentReportingPeriod = props.reportingPeriods ? props.reportingPeriods.get(currentReportingPeriodId) : null;
    return (
      <div className="container-fluid">
        <Header company={props.company} project={props.project} isRootRef={props.isRootRef} cref={props.cref} />
        <ReportingPeriodsDropdown
          reportingPeriods={props.reportingPeriods}
          selectedReportingPeriod={currentReportingPeriod}
          updateReportingPeriodId={updateReportingPeriodId}
        />
        <ClientPortions
          supplierRequests={props.supplierRequests}
          projectId={props.projectId}
          refId={props.crefId}
          reportingPeriodId={currentReportingPeriodId}
        />
        <SubmitToClients
          trackerId={props.trackerId}
          refId={props.refId}
          projectId={props.projectId}
          reportingPeriodId={currentReportingPeriodId}
          errors={props.errors}
          status={getStatus(props.reportingPeriods, currentReportingPeriodId)}
        />
        <div className="row p-3 m-3" />
        <SubmittedReports reportingPeriods={props.reportingPeriods} />
        <AcceptedRequestPanel projectId={props.projectId} />
        <ProjectInviteRequestPanel projectId={props.projectId} />
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  let { projectId, refId, reportingPeriodId } = Traec.utils.getFullIds(state, ownProps.match.params);
  let { company, project, tracker, trackerId, cref, crefId, rootRef, isRootRef } = getProjectProps(
    state,
    projectId,
    refId
  );
  let supplierRequests = state.getInPath(`entities.projectObjects.byId.${projectId}.requests`);
  let { reportingPeriods, currentReportingPeriod, currentReportingPeriodId } = getReportingPeriod(
    state,
    reportingPeriodId,
    projectId,
    refId
  );
  //console.log("REPORTING", reportingPeriods, currentReportingPeriod, currentReportingPeriodId);
  let errors = state.getInPath(`entities.errors.tracker.byId.${trackerId}.dispatch`);

  return {
    company,
    projectId,
    project,
    tracker,
    cref,
    crefId,
    rootRef,
    isRootRef,
    trackerId,
    refId,
    supplierRequests,
    reportingPeriods,
    reportingPeriodId,
    currentReportingPeriod,
    currentReportingPeriodId,
    errors
  };
};

export default connect(mapStateToProps)(ProjectApportionment);

const getStatus = (reportingPeriods, reportingPeriodId) => {
  try {
    let reportingPeriod = reportingPeriods.get(reportingPeriodId);
    return reportingPeriod.getInPath(`meta_json.apportionment.status`);
  } catch (e) {
    return null;
  }
};

const Header = props => {
  return (
    <div className="mb-3 pb-3">
      <h3>Apportionment to My Clients</h3>
      <BreadCrumb company={props.company} project={props.project} isRootRef={props.isRootRef} cref={props.cref} />
    </div>
  );
};

export const getCurrentReportingPeriod = (state, currentReportingPeriodId, projectId, refId) => {
  let currentReportingPeriod = null;
  if (!currentReportingPeriodId) {
    currentReportingPeriod = state.getInPath(`entities.refs.byId.${refId}.latest_commit.reporting_period_data`);
  } else {
    currentReportingPeriod = state.getInPath(
      `entities.projectReportingPeriods.byId.${projectId}.${currentReportingPeriodId}`
    );
  }

  return currentReportingPeriod;
};

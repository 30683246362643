import React, { useEffect } from "react";
import Traec from "traec";
import { BSBtnDropdown, BSCard, BSBtn } from "traec-react/utils/bootstrap";
import { connect } from "react-redux";
import { getProjectProps } from "AppSrc/project/utils";

export const PendingSupplierRequests = props => {
  if (!props.project.getInPath("meta_json.allowSuppliers")) {
    return null;
  }

  new Traec.Fetch("project_supplier", "list", { projectId: props.projectId }).dispatch();
  new Traec.Fetch("project_authgroup", "list", { projectId: props.projectId }).dispatch();

  return (
    <BSCard
      title="Pending Supplier Requests"
      body={
        <RequestRows
          requests={props.supplierRequests}
          authGroups={props.authGroups}
          refId={props.crefId}
          commitId={props.commitId}
          trackerId={props.trackerId}
          treeId={props.rootTreeId}
          projects={props.projects}
        />
      }
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  let { project } = ownProps;
  let projectId = project.get("uid");
  let projects = state.getInPath(`entities.projects.byId`);
  let supplierRequests = projectId ? state.getInPath(`entities.projectObjects.byId.${projectId}.requests`) : null;
  let authGroups = projectId ? state.getInPath(`entities.projectObjects.byId.${projectId}.authGroups`) : null;
  let { trackerId, cref, crefId } = getProjectProps(state, projectId, null);
  let commit = cref ? cref.get("latest_commit") : null;
  let commitId = commit ? commit.get("uid") : null;
  let rootTreeId = commit ? commit.getIn(["tree_root", "uid"]) : null;

  return { projectId, project, projects, supplierRequests, authGroups, trackerId, cref, crefId, commitId, rootTreeId };
};

export default connect(mapStateToProps)(PendingSupplierRequests);

const RequestRows = props => {
  if (!props.requests) {
    return <div>No pending requests</div>;
  }

  let pendingRequests = props.requests.filter(request => {
    if (request.get("status") === "outstanding") {
      return request;
    }
  });

  if (!pendingRequests.size) {
    return <div>No pending requests</div>;
  }

  return pendingRequests
    .valueSeq()
    .map((request, index) => (
      <RequestRow
        key={index}
        index={index}
        request={request}
        authGroups={props.authGroups}
        trackerId={props.trackerId}
        refId={props.refId}
        commitId={props.commitId}
        treeId={props.treeId}
        projects={props.projects}
      />
    ));
};

const RequestRow = props => {
  // Get the default authority group to use
  let defaultAuthGroup = (props.authGroups || Traec.Im.Map())
    .toList()
    .filter(i => i.get("name").startsWith("Report"))
    .first();
  defaultAuthGroup = defaultAuthGroup ? defaultAuthGroup.toJS() : { name: "Select Authority Group", uid: null };

  //
  const [state, setState] = React.useState({
    supplierName: null,
    authGroup: defaultAuthGroup,
    showAccept: false,
    refName: null
  });

  const handleAction = (e, action) => {
    if (action === "accepted") {
      handleAcceptance(action);
    } else {
      handleRejecting(action);
    }
  };

  const handleAcceptance = action => {
    let fetch = new Traec.Fetch("tracker_ref_tree_branch", "post", {
      trackerId: props.trackerId,
      refId: props.refId,
      commitId: props.commitId,
      treeId: props.treeId,
      skip_categories: true
    });
    fetch.updateFetchParams({
      body: {
        name: "reporting package tree",
        ref_name: state.refName
          ? state.refName
          : `${props.request.getIn(["supplier_discipline", "project", "name"])} - Reporting Package`
      },
      nextHandlers: [
        (data, post, orgPost) => {
          let nextFetch = new Traec.Fetch("project_supplier", "put", {
            projectId: props.request.getIn(["client_project", "uid"]),
            requestId: props.request.get("uid")
          });
          nextFetch.updateFetchParams({
            body: {
              status: action,
              new_name: state.supplierName
                ? state.supplierName
                : `${props.request.getIn(["supplier_discipline", "project", "name"])} - Supplier`,
              auth_group: state.authGroup.uid,
              client_ref: data.target.ref.uid
            }
          });
          return nextFetch.params;
        }
      ]
    });
    fetch.dispatch();
  };

  const handleRejecting = action => {
    let fetch = new Traec.Fetch("project_supplier", "put", {
      projectId: props.request.getIn(["client_project", "uid"]),
      requestId: props.request.get("uid")
    });
    fetch.updateFetchParams({
      body: {
        status: action
      }
    });
    fetch.dispatch();
  };

  const toggleAcceptForm = e => {
    setState({ ...state, showAccept: !state.showAccept });
  };

  const updateAuth = (e, auth) => {
    setState({ ...state, authGroup: { uid: auth.get("uid"), name: auth.get("name") } });
  };

  const updateSupplierName = e => {
    setState({ ...state, supplierName: e.target.value });
  };

  const updateRefName = e => {
    setState({ ...state, refName: e.target.value });
  };

  let supplierProjectName = props.request.getInPath("supplier_discipline.project.name");
  let supplierCompanyName = props.request.getInPath("supplier_company.name");

  return (
    <div className="row" style={{ backgroundColor: (props.index + 1) % 2 ? "#ddd" : "" }}>
      <div className="col-3 mt-1 mb-1 pt-1 pb-1">{`${supplierCompanyName} - ${supplierProjectName}`}</div>
      <div className="col-8">
        <AcceptForm
          show={state.showAccept}
          updateSupplierName={updateSupplierName}
          updateRefName={updateRefName}
          refName={`${props.request.getInPath("supplier_discipline.project.name")}`}
          supplierName={`${props.request.getInPath("supplier_company.name")}`}
          updateAuth={updateAuth}
          authGroups={props.authGroups}
          selectedAuth={state.authGroup}
        />
      </div>
      <div className="col-1 mt-1 mb-1 pt-1 pb-1">
        {state.showAccept ? (
          <BSBtn text="Accept" onClick={e => handleAction(e, "accepted")} />
        ) : (
          <BSBtnDropdown
            links={[
              { name: "Accept", onClick: e => toggleAcceptForm(e) },
              { name: "Reject", onClick: e => handleAction(e, "rejected") }
            ]}
          />
        )}
      </div>
    </div>
  );
};

const AcceptForm = props => {
  if (!props.show) {
    return null;
  }

  let links = props.authGroups.valueSeq().map(auth => {
    return { name: auth.get("name"), onClick: e => props.updateAuth(e, auth) };
  });

  return (
    <div className="row m-1 p-1">
      <div className="input-group input-group-sm col-4">
        <input className="form-control" defaultValue={props.refName} type="text" onChange={props.updateRefName} />
      </div>
      <div className="input-group input-group-sm col-4">
        <input
          className="form-control"
          defaultValue={props.supplierName || "Give supplier a name"}
          type="text"
          onChange={props.updateSupplierName}
        />
      </div>

      <div className="col-4">
        <BSBtnDropdown header={props.selectedAuth.name} links={links.toJS()} />
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { BSBtn } from "traec-react/utils/bootstrap";
import Traec from "traec";
import { alertSuccess } from "traec-react/utils/sweetalert";

export const SubmitToClients = props => {
  const [state, setState] = React.useState({
    disabled: false,
    warnings: null
  });

  const onSuccess = () => {
    new Traec.Fetch("project_reporting_periods", "list", { projectId: props.projectId }).dispatch();
    return alertSuccess({
      text: "Report submitted to client(s). You will receive an email when the submission is done."
    });
  };

  useEffect(() => {
    checkStatus();
  });

  const submitOnClick = e => {
    e.preventDefault();
    let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId: props.trackerId });
    fetch.updateFetchParams({
      body: {
        type: "SUBMIT_APPORTIONMENTS",
        payload: {
          supplier_ref_id: props.refId,
          reporting_period_id: props.reportingPeriodId
        }
      },
      headers: { "content-type": "application/json" },
      rawBody: false,
      postSuccessHook: () => onSuccess()
    });
    setState({ disabled: true });
    fetch.dispatch();
  };

  const checkStatus = () => {
    let warnings = null;
    let disabled = false;
    if (props.status === "submitting") {
      warnings = "Currently Submitting. Please wait for confirmation email";
      disabled = true;
    } else if (props.status === "failed") {
      warnings = "Submission failed. Please try again or contact administrators if the error persist";
      disabled = false;
    } else if (props.status === "submitted") {
      warnings = null;
      disabled = true;
    }
    if (warnings !== state.warnings || disabled !== state.disabled) {
      setState({ warnings, disabled });
    }
  };

  return (
    <div className="pb-5 mb-5">
      <BSBtn
        text={buttonText(props.status)}
        onClick={submitOnClick}
        trackerId={props.trackerId}
        refId={props.refId}
        reportingPeriodId={props.reportingPeriodId}
        disabled={state.disabled}
      />
      <DisplayError errors={props.errors} warnings={state.warnings} />
    </div>
  );
};

const DisplayError = props => {
  let errors = null;
  let warnings = null;
  if (props.errors && props.errors.size) {
    errors = props.errors.map(err => <div className="list-group-item list-group-item-danger">{err}</div>);
  }
  if (props.warnings) {
    warnings = <div className="list-group-item list-group-item-warning">{props.warnings}</div>;
  }
  if (errors || warnings) {
    return (
      <div className="list-group float-right col-2">
        {errors}
        {warnings}
      </div>
    );
  }

  return null;
};

const buttonText = status => {
  if (status === "submitting") {
    return "Submitting";
  } else if (status === "submitted") {
    return "Submitted";
  } else {
    return "Submit to Clients";
  }
};

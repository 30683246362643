import React, { useState } from "react";
import { connect } from "react-redux";
import Traec from "traec";
import { getNodeFromPath } from "traec/utils/nodes";
import { nestBaseMetric } from "AppSrc/project/report/reportMetricRow";
import MetricRowComponent from "./reportMetricRow";

import {
  MetricNameDescriptionCell,
  ReportValueInputCell,
  ReportCommentCell,
  ReportNoInputCheckbox,
  RowAdminPanel,
  isNotInFrequency,
} from "./reportMetricRow";
import RowErrorBoundary, { MiniErrorBoundary, CellSpanErrorBoundary } from "./error";

function SelectableMetricUnitCell({ score, descendantNodes, setCurrentScore, hide, disableInputs }) {
  if (hide) {
    return null;
  }

  if (disableInputs) {
    let currentNode = (descendantNodes || Traec.Im.Map())
      .toList()
      .filter((node) => node && !node.getInPath("meta_json.hidden") && node.get("_path") == score.get("_path"))
      .first();
    return <td className="border-0">{currentNode?.getInPath("metric.unit")}</td>;
  }

  let options = (descendantNodes || Traec.Im.Map())
    .toList()
    .filter((node) => node && !node.getInPath("meta_json.hidden")) // Filter out nodes marked as "hidden"
    .map((node, i) => (
      <option key={i} value={node.get("_path")}>
        {node.getInPath("metric.unit")}
      </option>
    ));

  return (
    <td className="border-0">
      <select
        className="form-control form-control-sm m-0 p-0"
        value={score.get("_path")}
        onChange={(e) => {
          console.log("Selected value", e.target.value);
          setCurrentScore(descendantNodes.get(e.target.value));
        }}
      >
        {options}
      </select>
    </td>
  );
}

function RowInputCells(props) {
  let { isRequiredPeriodically, ValueInputComponent, valueColSpan, commentColSpan, hideUnits, hideNoReport } = props;

  ValueInputComponent = ValueInputComponent || ReportValueInputCell;

  if (isNotInFrequency(props)) {
    return <NotRequiredCells {...isRequiredPeriodically} />;
  }

  return (
    <CellSpanErrorBoundary colSpan={4}>
      <SelectableMetricUnitCell {...props} hide={hideUnits} />
      <ValueInputComponent {...props} colSpan={valueColSpan || 1} />
      <ReportCommentCell {...props} colSpan={commentColSpan || 1} />
      <ReportNoInputCheckbox {...props} hide={hideNoReport} />
    </CellSpanErrorBoundary>
  );
}

function SelectTwinInputComponent(props) {
  let { score, rowStyle } = props;

  // set the score to be the currently selected child
  let _props = props;

  let highlight = (score.get("period") || -1) > 0;

  return (
    <React.Fragment>
      <tr style={rowStyle} className={highlight ? "font-weight-bold" : ""}>
        <MetricNameDescriptionCell {..._props} />
        <RowInputCells
          {..._props}
          ValueInputComponent={ReportValueInputCell}
          valueColSpan={1}
          commentColSpan={1}
          hideUnits={false}
          hideNoReport={false}
        />
        <td className="border-0">
          <MiniErrorBoundary>
            <RowAdminPanel {..._props} />
          </MiniErrorBoundary>
        </td>
      </tr>
    </React.Fragment>
  );
}

function SelectTwinChildrenRow(props) {
  let { descendantNodes, baseMetricValueMap } = props;

  let initScore = descendantNodes
    .toList()
    .filter((i) => baseMetricValueMap.has(i.getInPath("metric.uid")))
    .first();

  let [currentScore, setCurrentScore] = useState(initScore || descendantNodes.toList().first());

  let _path = currentScore.get("_path");

  return (
    <RowErrorBoundary>
      <MetricRowComponent
        {...props}
        InputComponent={SelectTwinInputComponent}
        setCurrentScore={setCurrentScore}
        path={_path}
      />
    </RowErrorBoundary>
  );
}

const mapStateToProps = (state, ownProps) => {
  let { commitId, commitNodes, path } = ownProps;

  let baseMetricValueMap = commitId
    ? state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues`) || Traec.Im.Map()
    : Traec.Im.Map();

  let descendantNodes = commitNodes
    .get("byPath")
    .filter((value, key) => key.startsWith(path) && key != path)
    .map((value, _path) => nestBaseMetric(state, getNodeFromPath(state, _path, commitNodes)));

  return { descendantNodes, baseMetricValueMap };
};

export default connect(mapStateToProps)(SelectTwinChildrenRow);

import React from "react";
import { BSCard, BSBtn } from "traec-react/utils/bootstrap";
import Traec from "traec";

export const ApportionmentDetails = props => {
  const [state, setState] = React.useState({
    apportionment: props.project.getInPath("meta_json.apportionment")
      ? props.project.getInPath("meta_json.apportionment")
      : false,
    allowSuppliers: props.project.getInPath("meta_json.allowSuppliers")
      ? props.project.getInPath("meta_json.allowSuppliers")
      : false
  });

  const toggleApportionment = e => {
    e.preventDefault();
    setState({ ...state, apportionment: !state.apportionment });
    saveApportionmentToTrack(!state.apportionment, props.project);
  };

  const toggleSuppliers = e => {
    e.preventDefault();
    setState({ ...state, allowSuppliers: !state.allowSuppliers });
    saveSupplierAllowanceToTrack(!state.allowSuppliers, props.project);
  };

  return (
    <div>
      <BSCard
        title="Apportionment"
        body={
          <IsApportionment
            apportionment={state.apportionment}
            allowSuppliers={state.allowSuppliers}
            toggleApportionment={toggleApportionment}
            toggleSuppliers={toggleSuppliers}
          />
        }
      />
    </div>
  );
};

const IsApportionment = props => {
  return (
    <div>
      <div className="mb-2 pb-2">
        Enable apportioned data to be submitted to clients:{" "}
        <BSBtn text={props.apportionment ? "On" : "Off"} onClick={props.toggleApportionment} />
      </div>
      <div>
        Enable suppliers to submit apportioned data to this project:{" "}
        <BSBtn text={props.allowSuppliers ? "On" : "Off"} onClick={props.toggleSuppliers} />
      </div>
    </div>
  );
};

const saveApportionmentToTrack = (value, project) => {
  let projectId = project.get("uid");
  let meta = project.get("meta_json");
  let name = project.get("name");
  meta = meta.set("apportionment", value);
  let fetch = new Traec.Fetch("project", "put", { projectId });
  fetch.updateFetchParams({
    body: { name: name, meta_json: meta }
  });
  fetch.dispatch();
};

const saveSupplierAllowanceToTrack = (value, project) => {
  let projectId = project.get("uid");
  let meta = project.get("meta_json");
  let name = project.get("name");
  meta = meta.set("allowSuppliers", value);
  let fetch = new Traec.Fetch("project", "put", { projectId });
  fetch.updateFetchParams({
    body: { name: name, meta_json: meta }
  });
  fetch.dispatch();
};

export const cfFields = {
  metric: { value: "", class: "col", endRow: true },
  factor: { value: "", class: "col" },
  source: { value: "", class: "col", endRow: true },
  unitFrom: { value: "", class: "col" },
  unitTo: { value: "", class: "col", endRow: true },
  startDate: { value: "", class: "col-sm-5" },
  endDate: { value: "", class: "col", endRow: true },
  description: { value: "", calss: "col", endRow: true }
};

import Im from "traec/immutable";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";

import BaseFormConnected from "traec-react/utils/form";
import { BSCard, BSBtn, BSBtnDropdown } from "traec-react/utils/bootstrap";
import { confirmDelete } from "traec-react/utils/sweetalert";

import { getProjectProps } from "AppSrc/project/utils";
import ReportingPeriodRow, { counter } from "./row";

const repPeriodChoices = ["MONTHS", "DAYS", "WEEKS", "QUARTERS", "YEARS"];

const reportingFields = {
  reporting_start: { label: "Reporting from: ", value: "", inputType: "date", class: "col" },
  reporting_end: { label: "Reporting to: ", value: "", inputType: "date", class: "col" },
  reporting_freq: {
    label: "Every",
    value: 1,
    class: "col",
    inputType: "number"
  },
  reporting_units: {
    label: "Reporting Increments",
    value: "MONTHS",
    class: "col",
    inputType: "select",
    endRow: true,
    options: repPeriodChoices.map((name, i) => (
      <option key={i} value={name}>
        {name}
      </option>
    ))
  }
};

const scheduleFields = {
  schedule: {
    label: "Comma-separated start-dates of each period in format (dd/mm/yy, dd/mm/yy, etc)",
    value: "",
    class: "col",
    endRow: true
  }
};

class ReportingPeriodPanel extends React.Component {
  constructor(props) {
    super(props);

    let { projectId } = props;

    this.state = {
      showForm: false,
      formParams: new Traec.Fetch("project", "patch", { projectId }).params,
      scheduleFormParams: new Traec.Fetch("project_reporting_periods", "post", { projectId }).params
    };

    this.requiredFetches = [new Traec.Fetch("project_reporting_periods", "list")];

    this.addToPost = this.addToPost.bind(this);
    this.onClick = this.onClick.bind(this);
    this.createFromSchedule = this.createFromSchedule.bind(this);
    this.clearEmpty = this.clearEmpty.bind(this);
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  onClick(e) {
    e.preventDefault();
    let { project, projectId } = this.props;
    let fetch = new Traec.Fetch("project", "patch", { projectId });
    fetch.updateFetchParams({
      postSuccessHook: () => {
        location.reload();
      }
    });
    this.setState({ formParams: fetch.params });
    // Set the start and end date
    if (project) {
      let startDate = project.getInPath("reporting.startDate");
      let endDate = project.getInPath("reporting.endDate");
      reportingFields.reporting_start.value = startDate;
      reportingFields.reporting_end.value = endDate;
      reportingFields.reporting_units.value = project.getInPath("reporting.freq_unit");
      reportingFields.reporting_freq.value = project.getInPath("reporting.freq_num");
    }
    // Toggle the form on
    fetch.toggleForm();
  }

  createFromSchedule(e) {
    e.preventDefault();
    let { projectId } = this.props;
    let fetch = new Traec.Fetch("project_reporting_periods", "post", { projectId });
    fetch.toggleForm();
  }

  clearEmpty(e) {
    e.preventDefault();
    let { projectId } = this.props;
    let fetch = new Traec.Fetch("project_reporting_periods", "post", { projectId });
    fetch.updateFetchParams({
      body: { clear_empty: true },
      postSuccessHook: () => location.reload()
    });
    confirmDelete({
      text: `This will delete all empty reporting periods after the last period that contains a report.  Are you sure you would like to proceed?`,
      onConfirm: () => {
        fetch.dispatch();
      }
    });
  }

  addToPost(post) {
    let { reporting_start, reporting_freq, reporting_units, reporting_end } = post;
    Object.assign(post, {
      reporting: {
        startDate: reporting_start,
        endDate: reporting_end,
        freq_unit: reporting_units,
        freq_num: reporting_freq
      }
    });
    return post;
  }

  render_row_headers() {
    return (
      <div className="row">
        <div className="col-sm-2">
          <b>From</b>
        </div>
        <div className="col-sm-2">
          <b>To</b>
        </div>
        <div className="col-sm-2 text-center">
          <b>Num Reports</b>
        </div>
        <div className="col-sm-5">
          <b>Id</b>
        </div>
        <div className="col-sm-1 text-right">
          <b>Admin</b>
        </div>
      </div>
    );
  }

  render_rows() {
    let { projectId, projectReportingPeriods, userPermission } = this.props;
    if (!projectReportingPeriods) {
      return null;
    }
    return projectReportingPeriods
      .toList()
      .sortBy(i => i.get("startDate"))
      .reverse()
      .map((reportingPeriod, i) => (
        <ReportingPeriodRow
          key={i}
          projectId={projectId}
          userPermission={userPermission}
          reportingPeriod={reportingPeriod}
        />
      ));
  }

  render() {
    const { project } = this.props;
    if (!project) {
      return "";
    }

    // reset the row counter (for alternating row colors)
    counter.row = 0;

    return (
      <BSCard
        widthOffset="col-sm-12"
        title="Reporting Periods"
        button={
          <BSBtnDropdown
            header={
              <BSBtn
                text="Admin"
                onClick={e => {
                  return null;
                }}
              />
            }
            links={[
              { name: "Edit Reporting Duration", onClick: this.onClick },
              { name: "Create from schedule", onClick: this.createFromSchedule },
              { name: "Delete empty periods from end", onClick: this.clearEmpty }
            ]}
          />
        }
        body={
          <React.Fragment>
            {this.render_row_headers()}
            {this.render_rows()}
          </React.Fragment>
        }
        form={
          <React.Fragment>
            <BaseFormConnected prePostHook={this.addToPost} params={this.state.formParams} fields={reportingFields} />
            <BaseFormConnected params={this.state.scheduleFormParams} fields={scheduleFields} />
          </React.Fragment>
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;
  let { project } = getProjectProps(state, projectId);

  // Get the projectReportingPeriods
  let path = `entities.projectReportingPeriods.byId.${projectId}`;
  let projectReportingPeriods = state.getInPath(path);

  // Get the user project permission object
  let userPermission = state.getInPath(`entities.projectObjects.byId.${projectId}.userPermission`);
  // Add this to props
  return { userPermission, project, projectId, projectReportingPeriods };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingPeriodPanel);

import React from "react";
import { ApproveForm, patchCommit, isApprover, getCommitStatus } from "AppSrc/project/commits/row";
import { BSBtn } from "traec-react/utils/bootstrap";
import { Redirect } from "react-router-dom";

export class ApproveRejectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      status: null,
      comment: ""
    };

    this.patchCommit = patchCommit.bind(this);
    this.ApproveRejectButton = approveRejectButton.bind(this);
  }

  render() {
    let { userPermission, commit, projectId, crefId, projectBaseDisciplineMap, projectDisciplines } = this.props;
    let commitStatus = getCommitStatus(commit);

    //console.log(this.state.showForm, commitStatus === "Pending Approval");
    if (isApprover(userPermission, commit, projectId, projectBaseDisciplineMap, projectDisciplines)) {
      if (this.state.showForm && !(commitStatus === "Pending Approval")) {
        return <Redirect to={`/project/${projectId.substring(0, 8)}/wpack/${crefId.substring(0, 8)}/evals`} />;
      }

      if (this.state.showForm && commitStatus === "Pending Approval") {
        return <ApproveForm {...{...this.props, state, setState: this.setState}}/>;
      }

      if (!this.state.showForm && commitStatus === "Pending Approval") {
        return this.ApproveRejectButton();
      }
    }
    return displayStatus(this.props.currentPeriodString, commitStatus);
  }
}

export const approveRejectButton = function() {
  return (
    <React.Fragment>
      <div className="m-2">
        <BSBtn
          text={"Approve"}
          extra_className="ml-3"
          onClick={e => this.setState({ status: "APPROVE", showForm: true })}
        />
      </div>
      <div className="m-2">
        <BSBtn text={"Reject"} onClick={e => this.setState({ status: "REJECT", showForm: true })} />
      </div>
    </React.Fragment>
  );
};

export const displayStatus = function(currentPeriodString, status) {
  return (
    <div>
      <p>
        Reporting period <b>{currentPeriodString}</b> is <b>{status}</b>
      </p>
    </div>
  );
};

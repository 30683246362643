import React, { useEffect } from "react";
import { connect } from "react-redux";

import Traec from "traec";
import { BSCard } from "traec-react/utils/bootstrap";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { RightArrow } from "traec-react/utils/entities";

const setConvFactorGroup = (trackerId, refId, group_name) => {
  console.log("Updating latest_commit.meta_json.conversion_factor_group:", trackerId, refId, group_name);
  let fetch = new Traec.Fetch("tracker_ref", "patch", { trackerId, refId });
  fetch.updateFetchParams({
    preFetchHook: data => ({
      latest_commit: {
        meta_json: {
          conversion_factor_group: group_name
        }
      }
    })
  });
  fetch.dispatch();
};

function ConversionFactorSet(props) {
  let { trackerId, refId, value, groups } = props;

  // Get the requird fetches
  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [new Traec.Fetch("tenant_admin_conversion_group", "list")]
    });
  });

  // Select the list of options
  let options = groups
    .unshift(Traec.Im.fromJS({ name: "latest" }))
    .unshift(Traec.Im.fromJS({ name: "" }))
    .map((group, i) => (
      <option key={i} value={group.get("name")}>
        {group.get("name")}
      </option>
    ));

  return (
    <BSCard
      widthOffset="col-sm-12"
      title="Conversion Factors"
      body={
        <ErrorBoundary>
          <small className="text-muted">
            Select the conversion factor set that will be used to obtain globally available conversion factors.
            <br />
            The global factors may be overridden locally in a Project, Reporting Package (or Template) by using the
            Admin <RightArrow /> Metrics Menu then selecting the relevant metric and selecting "Add/Edit Conversion
            Factor" in the dropdown menu to the right.
            <br />
            <b>
              Selecting "latest" will use the latest available conversion factor set that is relevant at the end of the
              reporting period for a given report
            </b>
          </small>
          <div className={`form-group mt-2`}>
            <select
              className={`custom-select`}
              onChange={e => setConvFactorGroup(trackerId, refId, e.target.value)}
              value={value || ""}
            >
              {options}
            </select>
          </div>
        </ErrorBoundary>
      }
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  let groups = (state.getInPath("entities.conversionFactorGroups.byId") || Traec.Im.Map()).toList();
  return { groups };
};

export default connect(mapStateToProps)(ConversionFactorSet);

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Traec from "traec";
import { getPathChildren } from "traec/utils/nodes";

import { ErrorBoundary } from "traec-react/errors";
import { BSBtn, BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected, { BaseForm } from "traec-react/utils/form";
import { DropzoneButton } from "traec-react/utils/documentUpload/dropZone";
import { Spinner } from "traec-react/utils/entities";

import { getProjectProps, BreadCrumb } from "AppSrc/project/utils";
import MetricCategories from "./metricCategories";

import { SetMetaDataFields } from "AppSrc/forms/meta";

import Swal from "sweetalert2";

export const categoryNames = [
  "Air Quality",
  "Age",
  "Awards",
  "Biodiversity",
  "BREEAM",
  "Carbon",
  "Climate Change",
  "Community",
  "Company policies",
  "Disability",
  "Diversity & Equality",
  "Employee Engagement",
  "Employees",
  "EMS",
  "Ethics",
  "Ethnicity",
  "Future of Work",
  "Gender",
  "Governance",
  "Governance & Safety",
  "Health & Safety",
  "Materials",
  "Pay gap",
  "People Wellbeing",
  "Process & Productivity",
  "Procurement",
  "Recruitment",
  "Religion and belief",
  "Social Value",
  "Survey statistics",
  "Sexual orientation",
  "Waste",
  "Water",
  //"Traction",
  //"Non-traction",
  "Project Attributes",
  "Other"
];

export const metricCounters = {
  row: 0
};

const setCommitMeta = (cref, meta_json) => {
  if (!cref) {
    return null;
  }
  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: cref.getInPath("tracker"),
    refId: cref.get("uid"),
    commitId: cref.getInPath("latest_commit.uid")
  });
  fetch.updateFetchParams({
    body: { meta_json },
    postSuccessHook: e => location.reload()
  });
  fetch.dispatch();
};

function SetReportLayout({ cref }) {
  if (!cref) {
    return null;
  }
  let current = cref.getInPath("latest_commit.meta_json.report_layout") || "classic";
  let options = {
    tabbed: "Tabbed layout",
    classic: "Classic layout"
  };
  let _options = Object.entries(options).map(([key, value], i) => (
    <option key={i} value={key}>
      {value}
    </option>
  ));
  return (
    <form className="float-left">
      <select
        value={current}
        className="form-control form-control-sm"
        onChange={e => {
          e.preventDefault();
          setCommitMeta(cref, { report_layout: e.target.value });
        }}
      >
        {_options}
      </select>
    </form>
  );
}

function SetCommitMeta({ cref }) {
  if (!cref) {
    return null;
  }

  let _value = cref.getInPath("latest_commit.meta_json.submit_placeholder");

  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: cref.getInPath("tracker"),
    refId: cref.get("uid"),
    commitId: cref.getInPath("latest_commit.uid")
  });
  fetch.updateFetchParams({
    preFetchHook: body => {
      return {
        meta_json: body
      };
    },
    postSuccessHook: e => location.reload()
  });

  return (
    <BaseFormConnected
      params={fetch.params}
      forceShowForm={true}
      submitBtnText={"Save placeholder text"}
      fields={{
        submit_placeholder: {
          label: "Set Report submit comment placeholder text",
          value: _value,
          class: "col-sm-12",
          placeholder: "Write a comment...",
          endRow: true
        }
      }}
    />
  );
}

const importFromJSON = ({ target, trackerId, commitId }) => {
  let file = target.files[0];
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });

  if (file) {
    let formData = new FormData();
    formData.append("fileobj", file);
    formData.append("type", "IMPORT_FROM_JSON");
    formData.append("payload", JSON.stringify({ commitId }));
    fetch.updateFetchParams({ body: formData });

    fetch.updateFetchParams({
      body: formData,
      postSuccessHook: () => {
        alertSuccess({
          text: `JSON file uploaded`,
          onConfirm: () => {
            location.reload();
          }
        });
      }
    });

    fetch.dispatch();
  }
};

const exportToCSV = ({ trackerId, commitId }) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });

  let formData = new FormData();
  formData.append("type", "METRICS_TO_CSV");
  formData.append("payload", JSON.stringify({ commitId }));
  fetch.updateFetchParams({ body: formData });

  Swal.queue([
    {
      title: "Export",
      confirmButtonText: "Generate CSV file",
      html: "<p>This may take a minute. Please be patient.</p>",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch
          .rawFetch()
          .then(response => response.blob())
          .then(data => {
            console.log("GOT REPORT DATA", data);
            let blobUrl = window.URL.createObjectURL(data);
            Swal.insertQueueStep({
              showConfirmButton: false,
              showCancelButton: false,
              title: "Download ready",
              html: `<p>Click here to download</p><a class="btn btn-primary" href="${blobUrl}" download="report_${commitId.substring(
                0,
                8
              )}_metrics.csv">Download</a>`
            });
          })
          .catch(err => {
            Swal.insertQueueStep({
              type: "error",
              title: "Error",
              text: "There was an error generating your report.  Please contact support if the problem persists."
            });
          });
      }
    }
  ]);
};

class ProjectMetrics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedEdges: false,
      fetchedUrls: {},
      showDocs: false,
      selectedFiles: [],
      nameFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {}
      }
    };

    this.requiredFetches = [new Traec.Fetch("project_tracker", "list"), new Traec.Fetch("tracker_node", "list")];

    // Bind methods on this
    this.addCategory = this.addCategory.bind(this);
    this.postCommit = this.postCommit.bind(this);
    this.postCSVFile = this.postCSVFile.bind(this);
  }

  /**********************
    COMPONENT METHODS
  **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  getUrlParams() {
    const { crefId, rootTree, trackerId, commitId } = this.props;
    const rootTreeId = rootTree ? rootTree.get("uid") : null;
    return { crefId, commitId, trackerId, rootTreeId };
  }

  categoryFields() {
    let { allowedCategoryNames: names } = this.props;
    return {
      name: {
        value: names ? names[0] : "", // Select the first name by default
        class: "col",
        inputType: "select",
        endRow: true,
        options: names.map((name, i) => (
          <option key={i} value={name}>
            {name}
          </option>
        )) // Select the first name by default
      }
    };
  }

  postCommit(e) {
    e.preventDefault();
    let { trackerId, crefId, commitId } = this.getUrlParams();
    let fetch = new Traec.Fetch("tracker_ref_commit", "post", { trackerId, refId: crefId, commitId });
    fetch.updateFetchParams({
      body: {
        comment: `Saved metric tree state ${new Date()}`,
        recurse: false
      }
    });
    fetch.dispatch();
  }

  /**********************
    MENU METHODS
    **********************/

  addCategory(e) {
    e.preventDefault();
    let { trackerId, crefId, commitId } = this.getUrlParams();
    let fetch = new Traec.Fetch("tracker_node", "post", { trackerId, refId: crefId, commitId });
    fetch.updateFetchParams({
      preFetchHook: data => {
        return {
          //path: pathId,  // Not specifying a path will put it under the root tree
          type: "tree",
          node: {
            tree: data
          }
        };
      }
    });

    this.setState({ nameFormParams: fetch.params });
    fetch.toggleForm();
  }

  postCSVFile(e) {
    let { trackerId, crefId: refId } = this.props;
    let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });
    //
    let formData = new FormData();
    formData.append("fileobj", this.state.selectedFiles[0]);
    formData.append("type", "METRICS_FROM_CSV");
    formData.append("payload", JSON.stringify({ refId }));
    fetch.updateFetchParams({ body: formData });

    Swal.queue([
      {
        title: "Bulk-creation of metrics from CSV",
        confirmButtonText: "Start",
        html:
          "<p>The CSV file you have selected will try to be parsed and metrics created.</p>  <p>Existing metrics will not be affected.  This is an append-only operation.</p>  <p>This may take a while if you have many metrics.  Please be patient.</p>",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch
            .rawFetch()
            .then(response => response.json())
            .then(data => {
              Swal.insertQueueStep({
                type: "success",
                showConfirmButton: true,
                showCancelButton: false,
                title: "Metrics created successfully",
                html: "<p>The page will now reload</p>",
                confirmButtonText: "Done",
                onClose: () => {
                  location.reload();
                }
              });
            })
            .catch(err => {
              Swal.insertQueueStep({
                type: "error",
                title: "Error",
                text: "There was an error generating your report.  Please contact support if the problem persists.",
                onClose: () => {
                  location.reload();
                }
              });
            });
        }
      }
    ]);
  }

  dropDownLinks() {
    let { projectId, crefId, cref, sortKey, trackerId, commitId } = this.props;
    let thisItems = [
      { name: "Add Sustainability Issue", onClick: this.addCategory },
      {},
      { name: "Add from Template", linkTo: `${this.props.match.url}/add_from_template` },
      {
        name: "Add from CSV file",
        onClick: () => {
          this.setState({ showFileSelect: true });
        }
      },
      {},
      {
        name: sortKey == "_path" ? "Sort alphabetically" : "Sort by path",
        onClick: () => {
          setCommitMeta(cref, { sortKey: sortKey == "_path" ? "name" : "_path" });
        }
      },
      {},
      {
        name: "View as Report",
        onClick: () => {
          let reportUrl = `/project/${projectId.substring(0, 8)}/wpack/${crefId.substring(0, 8)}/report`;
          window.location.assign(reportUrl);
        }
      },
      {},
      {
        name: "Export to CSV",
        onClick: () => {
          exportToCSV({ trackerId, commitId });
        }
      },
      {
        name: "Export to JSON",
        onClick: () => {
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = `/api-docs/auth/login/?next=/api/tracker/${trackerId}/commit/${commitId}/node/?format=json`;
          //link.download = `${trackerId.substring(0, 8)}_${commitId.substring(0, 8)}.json`;
          link.target = "_blank";
          link.rel = "noreferrer noopener";
          document.body.appendChild(link);
          link.click();
        }
      },
      {
        name: "Import from JSON",
        onClick: () =>
          setAndShowModal("IMPORT_JSON", {
            title: `Import from JSON File`,
            body: (
              <>
                <div className="row text-center">
                  <div className="container-fluid">
                    <p className="text-center">Import from JSON file</p>
                    <label className="btn btn-sm btn-secondary mt-2 py-0 pointer" style={{ height: "31px" }}>
                      Select new File
                      <input
                        style={{ visibility: "hidden", height: "0px", width: "0px" }}
                        type="file"
                        onChange={event => importFromJSON({ target: event.target, trackerId, commitId })}
                      />
                    </label>
                  </div>
                </div>
              </>
            )
          })
      }
      //{ name: "Save State", onClick: this.postCommit }
    ];
    return thisItems;
  }

  /**********************
    RENDER METHODS
    **********************/

  render_loading() {
    let { commitNodes } = this.props;
    let hasNodes = commitNodes ? commitNodes.get("byPath").isEmpty() : false;
    if (!hasNodes) {
      return <Spinner />;
    }
  }

  render_category_form() {
    let { commitNodes, cref } = this.props;
    if (!commitNodes) {
      return null;
    }
    return (
      <React.Fragment>
        <ErrorBoundary>
          <SetReportLayout cref={cref} />
        </ErrorBoundary>

        <BSBtnDropdown links={this.dropDownLinks()} header={<span>Set up Metrics</span>} />
        <div style={{ clear: "both" }} />
        <BaseFormConnected params={this.state.nameFormParams} fields={this.categoryFields()} />
        <div style={{ clear: "both" }} />
      </React.Fragment>
    );
  }

  render_file_select() {
    if (!this.state.showFileSelect) {
      return null;
    }

    let files = this.state.selectedFiles.map(file => (
      <a key={file.name}>
        Upload {file.name}? ({(file.size / 1e6).toFixed(1)}Mb)
      </a>
    ));

    // Give a warning if the file is too large
    let confirmButton = null;
    if (this.state.selectedFiles.filter(file => file.size / 1e6 > 20).length) {
      files = [
        <span key={0} className="alert-danger">
          Maximum allowed upload size is 20Mb
        </span>
      ];
    } else {
      confirmButton = (
        <BSBtn
          text={"Upload"}
          onClick={e => this.postCSVFile(e)}
          extra_className="pl-1 pr-1 m-0 p-0"
          noFloatRight={true}
        />
      );
    }

    return (
      <div className="row">
        <div className="col-sm-8">
          <div className="float-right">
            <DropzoneButton
              onDrop={files => {
                this.setState({ selectedFiles: files });
              }}
              extra_className="pl-1 pr-1 m-0 p-0"
              selectAreaText="Select file"
              confirmButton={confirmButton}
              selectedFiles={files}
              onCancelUpload={() => {
                this.setState({ selectedFiles: [] });
              }}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <button
            className="btn btn-sm btn-secondary pl-1 pr-1 float-right m-0 p-0"
            onClick={() => this.setState({ selectedFiles: [], showFileSelect: false })}
          >
            Done
          </button>
        </div>
      </div>
    );
  }

  onDragEnd(result) {
    console.log("CALLING onDragEnd!", result);
  }

  render() {
    const { company, cref, project, isRootRef, tracker, commitId, rootTreeId, rootTreePath, sortKey } = this.props;
    if (!project || !tracker) {
      return "";
    }
    metricCounters.row = 0;
    let commit = cref ? cref.get("latest_commit") : null;
    return (
      <React.Fragment>
        <h3>Project Metrics</h3>
        <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} />

        {/* Button for adding top-level tree objects */}
        <ErrorBoundary>
          {this.render_category_form()}
          {this.render_file_select()}
        </ErrorBoundary>

        {/* Render the categories and row */}
        <ErrorBoundary>
          {/*<DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">*/}
          <MetricCategories
            cref={cref}
            commitId={commitId}
            rootTreeId={rootTreeId}
            rootTreePath={rootTreePath}
            sortKey={sortKey}
          />
          {/*</Droppable>
           </DragDropContext>*/}
        </ErrorBoundary>

        {/* Set defined meta-data input fields for the form */}
        <hr />
        <div className="container-fluid mt-3">
          <div className="col-sm-12">
            <p>
              <b>Additional meta-data</b>
            </p>
            <SetMetaDataFields
              saveMetaFetchProps={{
                handler: "tracker_ref_commit",
                method: "patch",
                params: {
                  trackerId: commit.get("tracker"),
                  refId: commit.get("ref"),
                  commitId: commit.get("uid")
                }
              }}
              metaJson={commit.get("meta_json")}
            />
            <div style={{ clear: "both" }} />
          </div>
        </div>

        <hr />
        <ErrorBoundary>
          <div className="container-fluid mt-3">
            <SetCommitMeta cref={cref} />
          </div>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //const { _projectId, _refId } = ownProps.match.params;
  const { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, tracker, trackerId, cref, crefId, isRootRef } = getProjectProps(state, projectId, refId);
  // Get the latest (working) commit
  let commit = cref ? cref.get("latest_commit") : null;
  let commitId = commit ? commit.get("uid") : null;
  // Get the root tree to start with
  let rootTree = commit ? commit.get("tree_root") : null;
  let rootTreeId = rootTree ? rootTree.get("uid") : null;
  // Get all of the trees that are children of the rootTree (edges)
  let commitNodes = commitId ? state.getInPath(`entities.commitNodes.${commitId}`) : null;

  // Adjust the category selection form (to avoid adding categories twice)
  let allowedCategoryNames = categoryNames;
  let pathRoot = null;
  if (commitNodes) {
    pathRoot = commitNodes.get("pathRoot");
    let subTrees = getPathChildren(state, pathRoot, commitNodes, "trees").filter(i => i);
    let subTreeNames = new Set(subTrees.map(tree => tree.get("name")));
    allowedCategoryNames = categoryNames.filter(s => !subTreeNames.has(s));
  }

  // sort alphabetically by default
  let sortKey = commit ? commit.getInPath("meta_json.sortKey") || "name" : "name";

  // Add this to props
  return {
    tracker,
    trackerId,
    projectId,
    project,
    company,
    cref,
    crefId,
    isRootRef,
    commitId,
    rootTree,
    rootTreeId,
    rootTreePath: pathRoot,
    commitNodes,
    allowedCategoryNames,
    sortKey
  };
};

export default connect(mapStateToProps)(ProjectMetrics);

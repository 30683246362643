import React from "react";
import Traec from "traec";
import { connect } from "react-redux";
import Octicon from "react-octicon";

import ErrorBoundary from "./error";
import { getNodeFromPath } from "traec/utils/nodes";
import { SubNodes } from "./node";

function CategoryTitleRow({ tree, collapsed, hide }) {
  if (hide) {
    return null;
  }
  let treeId = tree.get("uid");
  let treeName = tree.get("name");
  return (
    <tr>
      <td colSpan="9" className="border-0">
        <a data-toggle="collapse" href={`#${treeId}`} aria-expanded={"false"}>
          <Octicon className="expand_caret m-0 p-0" name={collapsed ? "triangle-right" : "triangle-down"} />
          <b>{treeName}</b>
        </a>
      </td>
    </tr>
  );
}

class ReportCategoryItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      metricFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {}
      },
      collapsed: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    let { tree } = this.props;
    let categoryName = tree ? tree.get("name") : "";

    // Check if the props keys have changed
    let curKeys = Traec.Im.Set(Object.keys(this.props));
    let newKeys = Traec.Im.Set(Object.keys(nextProps));
    if (!curKeys.equals(newKeys)) {
      console.log("Updating because category props keys have changed", categoryName, curKeys, newKeys);
      return true;
    }

    // Check if immutable structures in the props have changed
    for (let key of curKeys) {
      let _cur = this.props[key];
      let _next = nextProps[key];
      if (Traec.Im.isImmutable(_cur)) {
        if (_cur && !_cur.equals(_next)) {
          console.log("Immutable prop has changed in category", categoryName, key);
          let _curKeys = Traec.Im.Set(_cur.keys());
          let equalKeys = _curKeys && _curKeys.equals(Traec.Im.Set(_next.keys()));
          // For debugging
          console.log(" - Are keys the same?", equalKeys);
          if (equalKeys) {
            for (let key of _cur.keys()) {
              let _c = _cur.get(key);
              let _n = _next.get(key);
              let isIm = Traec.Im.isImmutable(_c);
              if (isIm && !_c.equals(_n)) {
                console.log(" - Found different value", _c.toJS(), Traec.Im.isImmutable(_n) ? _n.toJS() : _n);
              } else if (!isIm && _c !== _n) {
                console.log(" - Found different value for non-immutable objects", _c, _n);
              }
            }
          }
          return true;
        }
      }
    }

    return false;
  }

  render() {
    let { tree, hideTitleRow } = this.props;
    console.log("RENDERING CATEGORY", tree ? tree.get("name") : null);
    return (
      <ErrorBoundary>
        <CategoryTitleRow tree={tree} hide={hideTitleRow} collapsed={this.state.collapsed} />
        <ErrorBoundary>
          <SubNodes {...this.props} sortKey="metric._path" indentLevel={hideTitleRow ? -1 : 0} />
        </ErrorBoundary>
        <tr />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { path, commitNodes } = ownProps;
  let tree = getNodeFromPath(state, path, commitNodes);
  return { tree };
};

export default connect(mapStateToProps)(ReportCategoryItem);

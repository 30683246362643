import Moment from "moment";
import React from "react";
import Im from "traec/immutable";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";

export const ReportingPeriodsDropdown = props => {
  let links = Im.List();

  try {
    links = props.reportingPeriods
      .toList()
      .sortBy(reportingPeriod => reportingPeriod.get("startDate"))
      .map(reportingPeriod => {
        return {
          name: `${Moment(reportingPeriod.get("startDate")).format("Do MMM YY")} - ${Moment(
            reportingPeriod.get("endDate")
          )
            .add(-1, "days")
            .format("Do MMM YY")}`,
          onClick: e => props.updateReportingPeriodId(e, reportingPeriod.get("uid"))
        };
      });
  } catch (e) {}
  let formattedReportingPeriod = props.selectedReportingPeriod
    ? `${Moment(props.selectedReportingPeriod.get("startDate")).format("Do MMM YY")} - ${Moment(
        props.selectedReportingPeriod.get("endDate")
      )
        .add(-1, "days")
        .format("Do MMM YY")}`
    : null;

  return (
    <div className="row mb-2 pb-2">
      <div className="col-12">
        <BSBtnDropdown header={`Reporting Period: ${formattedReportingPeriod}`} links={links.toJS()} />
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { connect } from "react-redux";
import Traec from "traec";
import { getNodeFromPath } from "traec/utils/nodes";
import Select from "react-dropdown-select";
import { SubNodes } from "./node";
import { nestBaseMetric } from "AppSrc/project/report/reportMetricRow";
import RowErrorBoundary from "./error";
import store from "traec/redux/store";
import { setUIItems } from "traec/redux/actionCreators";
import { data } from "jquery";

/* Reduce a path down to a map of all descendants.  Values of "false" will be rendered but hidden, however true will be fully rendered*/
const getShowPathDict = path => {
  if (!path || !path.length) {
    return {};
  }
  let depth = path.length / 7;
  let endPoints = Array(depth)
    .fill(1)
    .map((x, y) => 7 * (y + 1));
  let paths = endPoints.reduce((acc, cur) => ({ ...acc, [path.substring(0, cur)]: false }), {});
  paths = { ...paths, [path]: true };
  return paths;
};

function NoDataWarningRow(props) {
  return (
    <tr>
      <td colSpan={100}>No child nodes or path information to search</td>
    </tr>
  );
}

const getNodeIdFromPath = (commitNodes, path) => {
  return commitNodes.getInPath(`byPath.${path}`)?.get(`uid`);
};

const postValue = ({ trackerId, commitId, scoreId, value }) => {
  console.log("Posting value to server", trackerId, commitId, scoreId, value);

  let fetch = new Traec.Fetch("tracker_commit_score_value", "post", {
    trackerId,
    commitId,
    scoreId
  });

  fetch.updateFetchParams({
    preFetchHook: body => ({ value })
  });

  fetch.dispatch();
};

const getConversion = data =>
  Traec.Im.fromJS({
    fromPath: data.path,
    inputValue: data.value, //value of child
    convertedValue: data.value, //parent value after multiplied with conversion
    conversionFactor: 1,
    inputType: "select"
  });

const setConversionsInRedux = ({ commitId, parentPath, values }) => {
  let conversions = values.map(data => getConversion(data)).toList();
  let itemPath = `childConversions.${commitId}.${parentPath}`;
  //console.log("Saving conversions to parent in store", itemPath, conversions?.toJS());
  store.dispatch(setUIItems(conversions, { itemPath }));
};

const postChildValues = (props, value) => {
  let { rootPath: parentPath, commit, commitNodes } = props;

  // Get the properties of the tracker/commit we are on
  let commitId = commit?.get("uid");
  let trackerId = commit?.get("tracker");
  console.log("Selecting a single child of parent node: ", parentPath, trackerId, commitId);

  // This is the path of the selected child
  let { path: selectedChildPath } = value;

  let childPaths = commitNodes.getInPath(`byPath.${parentPath}.children.byPath`) || Traec.Im.Set();

  let childValues = childPaths.map(path => ({
    path: path,
    scoreId: getNodeIdFromPath(commitNodes, path),
    value: selectedChildPath == path ? 1 : null
  }));

  console.log("Setting child score values", childValues?.toJS());

  for (let data of childValues) {
    postValue({ ...data, trackerId, commitId });
  }

  setConversionsInRedux({ commitId, parentPath, values: childValues });
};

function SearchNodesRow(props) {
  let { rootPath: parentPath, indentLevel, nodes, show, setShow, commit, disableInputs } = props;

  let commitId = commit?.get("uid");
  let options = nodes
    .toList()
    .filter(node => node && !node.getInPath("meta_json.hidden")) // Filter out nodes marked as "hidden"
    .map((node, i) => ({
      path: node.get("_path"),
      name: node.getInPath("metric.name"),
      key: i
    }))
    .toJS();

  // Set the one to show as selected using a simple filter
  let values = options.filter(i => show[i.path]);

  return (
    <tr>
      <td colSpan={100}>
        <div style={{ marginLeft: `${(indentLevel + 1) * 1.5}em`, marginRight: "5em" }}>
          <Select
            disabled={disableInputs}
            options={options}
            values={values}
            labelField={"name"}
            valueField={"path"}
            clearable={true}
            searchable={true}
            searchBy={"name"}
            clearOnSelect={true}
            sortBy={"path"}
            placeholder={"Select an option"}
            onClearAll={() => {
              console.log("clearAll HEEREE", commitId, parentPath);
              postChildValues(props, { path: undefined });
              setConversionsInRedux({ commitId, parentPath, values: Traec.Im.List() });
            }}
            onChange={values => {
              if (!values || !values[0]) {
                return null;
              }
              postChildValues(props, values[0]);
            }}
          />
        </div>
      </td>
    </tr>
  );
}

const getInitShow = (baseMetricValueMap, nodes) => {
  // Set any nodes with baseMetric in baseMetricIdsWithValues
  let initShow = {};
  for (let [path, node] of nodes) {
    if (!node) {
      continue;
    }
    initShow[path] = baseMetricValueMap
      .get(node.getInPath("metric.uid"))
      ?.get("values")
      ?.toList()
      ?.first()
      ?.get("value");
  }
  console.log("GENERATED INIT SHOW MAP", Traec.Im.fromJS(initShow)?.toJS());
  return initShow;
};

function ChildSearchRow(props) {
  let { baseMetricValueMap, descendantNodes, commitNodes, path, commit, indentLevel, hide, disableInputs } = props;

  if (hide) {
    return null;
  }

  let [show, setShow] = useState(getInitShow(baseMetricValueMap, descendantNodes));

  if (!commitNodes || !path) {
    return <NoDataWarningRow />;
  }

  return (
    <React.Fragment>
      <SearchNodesRow
        disableInputs={disableInputs}
        commit={commit}
        commitNodes={commitNodes}
        nodes={descendantNodes}
        indentLevel={indentLevel}
        rootPath={path}
        show={show}
      />
      <RowErrorBoundary>
        <SubNodes {...props} parentNoReport={false} sortKey="metric.name" showOnly={{}} />
      </RowErrorBoundary>
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  let { commitId, commitNodes, path } = ownProps;

  let baseMetricValueMap = commitId
    ? state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues`) || Traec.Im.Map()
    : Traec.Im.Map();

  let descendantNodes = commitNodes
    .get("byPath")
    .filter((value, key) => key.startsWith(path) && key != path)
    .map((value, _path) => nestBaseMetric(state, getNodeFromPath(state, _path, commitNodes)));

  return { descendantNodes, baseMetricValueMap };
};

export default connect(mapStateToProps)(ChildSearchRow);

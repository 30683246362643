import React, { useState } from "react";
import { connect } from "react-redux";

import Traec from "traec";
import { getNodeFromPath } from "traec/utils/nodes";

import Select from "react-dropdown-select";

import { SubNodes } from "./node";
import { nestBaseMetric } from "AppSrc/project/report/reportMetricRow";

import RowErrorBoundary, { MiniErrorBoundary, CellSpanErrorBoundary } from "./error";

/* Reduce a path down to a map of all descendants.  Values of "false" will be rendered but hidden, however true will be fully rendered*/
const getShowPathDict = path => {
  if (!path || !path.length) {
    return {};
  }
  let depth = path.length / 7;
  let endPoints = Array(depth)
    .fill(1)
    .map((x, y) => 7 * (y + 1));
  let paths = endPoints.reduce((acc, cur) => ({ ...acc, [path.substring(0, cur)]: false }), {});
  paths = { ...paths, [path]: true };
  return paths;
};

function NoDataWarningRow(props) {
  return (
    <tr>
      <td colSpan={100}>No child nodes or path information to search</td>
    </tr>
  );
}

function SearchNodesRow({ disableInputs, rootPath, indentLevel, nodes, show, setShow }) {
  //console.log("AAA SearchNodesRow", disableInputs);

  let options = nodes
    .toList()
    .filter(node => node && !node.getInPath("meta_json.hidden")) // Filter out nodes marked as "hidden"
    .map((node, i) => ({
      path: node.get("_path"),
      name: node.getInPath("metric.name"),
      key: i
    }))
    .toJS();

  return (
    <tr>
      <td colSpan={100}>
        <div style={{ marginLeft: `${(indentLevel + 1) * 1.5}em`, marginRight: "5em" }}>
          <Select
            disabled={disableInputs}
            options={options}
            labelField={"name"}
            valueField={"path"}
            clearable={true}
            searchable={true}
            searchBy={"name"}
            clearOnSelect={true}
            sortBy={"path"}
            placeholder={"Search and select metrics to report against by typing part of metric Name or ID..."}
            onChange={values => {
              if (!values || !values[0]) {
                return null;
              }
              setShow({ ...show, ...getShowPathDict(values[0].path) });
            }}
          />
        </div>
      </td>
    </tr>
  );
}

const getInitShow = (baseMetricValueMap, nodes) => {
  // Set any nodes with baseMetric in baseMetricIdsWithValues
  let initShow = {};
  for (let [path, node] of nodes) {
    if (!node) {
      continue;
    }
    if (baseMetricValueMap.has(node.getInPath("metric.uid"))) {
      initShow[path] = node.getInPath("meta_json.hidden") === true ? false : true;
    }
  }
  console.log("GENERATED INIT SHOW MAP", initShow);
  return initShow;
};

function ChildSearchRow(props) {
  let { baseMetricValueMap, descendantNodes, commitNodes, path, indentLevel, hide, disableInputs } = props;
  if (hide) {
    return null;
  }

  let [show, setShow] = useState(getInitShow(baseMetricValueMap, descendantNodes));

  if (!commitNodes || !path) {
    return <NoDataWarningRow />;
  }

  console.log("RENDERING WITH showOnly:", show);
  return (
    <React.Fragment>
      <SearchNodesRow
        nodes={descendantNodes}
        disableInputs={disableInputs}
        indentLevel={indentLevel}
        rootPath={path}
        show={show}
        setShow={setShow}
      />
      <RowErrorBoundary>
        <SubNodes {...props} parentNoReport={false} sortKey="metric.name" showOnly={show} />
      </RowErrorBoundary>
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  let { commitId, commitNodes, path } = ownProps;

  let baseMetricValueMap = commitId
    ? state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues`) || Traec.Im.Map()
    : Traec.Im.Map();

  let descendantNodes = commitNodes
    .get("byPath")
    .filter((value, key) => key.startsWith(path) && key != path)
    .map((value, _path) => nestBaseMetric(state, getNodeFromPath(state, _path, commitNodes)));

  return { descendantNodes, baseMetricValueMap };
};

export default connect(mapStateToProps)(ChildSearchRow);

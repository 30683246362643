import React from "react";
import Traec from "traec";
import { SubNodes as _SubNodes } from "../metrics/node";

import Metric from "./reportMetricRow";
import Tree from "./reportMetricTree";
import Document from "./reportDocumentRow";

const componentMap = {
  metricscore: Metric,
  tree: Tree,
  document: Document
};

export function SubNodes(props) {
  componentMap["metricscore"] = Metric;
  componentMap["tree"] = Tree;
  componentMap["document"] = Document;
  return <_SubNodes {...props} componentMap={componentMap} />;
}

import React from "react";
import Im from "traec/immutable";
import { connect } from "react-redux";
import Traec from "traec";

import BaseFormConnected from "traec-react/utils/form";
import { BSCard, BSBtn } from "traec-react/utils/bootstrap";
import { ErrorBoundary } from "traec-react/errors/handleError";

import { ProjectPermission } from "traec/utils/permissions/project";
import { Spinner } from "traec-react/utils/entities";
import { workPackageDetailsFields } from "./form";
import { BreadCrumb, getProjectProps } from "AppSrc/project/utils";
import Moment from "moment";
import WorkPackageList from "./wppanel";

import { SetMetaDataFields } from "AppSrc/forms/meta";
import { getTerm } from "AppSrc/tree/utils";

const getPreDispatchHook = () => {
  return action => {
    action.fetchParams.headers = { "content-type": "application/json" };
    action.fetchParams.rawBody = false;
    //action.fetchParams.throttleTimeCheck = 1000 * 3600; // Throttle request to every hour (to prevent calling backend every click)
    action.stateParams.stateSetFunc = (state, data) => state;
    console.log("Calling tracker_dispatch for CLEAR_CACHE data", action);
    return action;
  };
};

const clearDashboardCache = props => {
  let { trackerId, refId } = props;
  let fetch = new Traec.Fetch(
    "tracker_dispatch",
    "post",
    { trackerId, refId },
    { preDispatchHook: getPreDispatchHook(props) }
  );
  fetch.updateFetchParams({
    body: {
      type: "SUSTOOL_DASHBOARD_DATA",
      payload: {
        part: "CLEAR_CACHE",
        refId
      }
    }
  });
  console.log("Dispatching clearDashboardCache");
  fetch.dispatch();
};

const pullFromTemplate = ({ trackerId, refId, tracker, cref }) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId, refId });

  let formData = new FormData();
  formData.append("type", "PULL_FROM_TEMPLATE");
  formData.append("payload", JSON.stringify({ ref_id: refId }));

  fetch.updateFetchParams({
    body: formData,
    postSuccessHook: () => {
      location.reload();
    }
  });
  fetch.dispatch();
};

function RenderID({ title, id }) {
  return (
    <tr>
      <td>
        <b>{title}</b>
      </td>
      <td>{id}</td>
    </tr>
  );
}

export function TechnicalDetails(props) {
  let { projectId, tracker, trackerId, refId } = props;
  let trackerTemplateId = tracker?.get("from_template");
  return (
    <ErrorBoundary>
      <BSCard
        widthOffset="col-sm-12"
        title="Technical details"
        body={
          <table width="100%">
            <tbody>
              <RenderID title="Full Project ID" id={projectId} />
              <RenderID title="Full Metric Tree ID" id={trackerId} />
              <RenderID title="Full Report Package ID" id={refId} />
              <RenderID title="Source Template ID" id={trackerTemplateId} />
              <tr style={{ paddingTop: "0.5rem" }}>
                <td>
                  <button className="btn btn-sm btn-warning mr-3" onClick={() => clearDashboardCache(props)}>
                    Clear dashboard cache
                  </button>
                  {trackerTemplateId ? (
                    <button className="btn btn-sm btn-outline-danger" onClick={() => pullFromTemplate(props)}>
                      Re-sync with template
                    </button>
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        }
      />
    </ErrorBoundary>
  );
}

const toggleStatus = (e, cref) => {
  e.preventDefault();
  let crefId = cref.get("uid");
  let commit = cref.get("latest_commit");

  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: commit.get("tracker"),
    refId: crefId,
    commitId: commit.get("uid")
  });

  fetch.updateFetchParams({
    body: {
      comment: "On hold status toggled",
      status: "HOLD"
    },
    postSuccessHook: () => {
      location.reload();
    }
  });

  fetch.dispatch();
};

export function ReportPeriodString(item) {
  return `${Moment(item.get("startDate")).format("Do MMM YY")} to ${Moment(item.get("endDate"))
    .add(-1, "days")
    .format("Do MMM YY")}`;
}

function OnHoldButton({ cref }) {
  if (!cref) {
    return null;
  }

  let commit_status = cref.getInPath("latest_commit.status.name");
  let on_hold = commit_status ? (commit_status.startsWith("Not for") ? true : false) : false;

  return (
    <div className="row mb-2">
      <div className="col-sm-12">
        <BSBtn
          text={on_hold ? "On Hold" : "Active"}
          onClick={e => toggleStatus(e, cref)}
          extra_className={on_hold ? "bg-danger" : "bg-success"}
          noFloatRight={true}
        />
        <small className="form-text text-muted">Click to toggle on-hold status</small>
      </div>
    </div>
  );
}

class RefDetails extends React.Component {
  constructor(props) {
    super(props);

    let { refId } = props.match.params;
    let fetch = new Traec.Fetch("tracker_ref", "patch", { trackerId: null, refId });

    this.state = {
      formParams: fetch.params,
      initFormFields: Im.Map(),
      setInitFields: false,
      isTemplate: false,
      isPublic: false,
      setStateFromTracker: false
    };

    this.requiredFetches = [
      new Traec.Fetch("project", "read"),
      new Traec.Fetch("project_discipline", "list"),
      new Traec.Fetch("company", "list"),
      new Traec.Fetch("project_reporting_periods", "list"),
      new Traec.Fetch("tracker_ref", "read")
    ];

    this.setPostData = this.setPostData.bind(this);
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
    this.setFormParams();
  }

  componentDidUpdate(prevProps) {
    Traec.fetchRequiredFor(this);
    // Set the initial form fields based on values in the Project
    let { tracker, trackerId, refId } = this.props;

    if (tracker && !this.state.setStateFromTracker) {
      let fetch = new Traec.Fetch("tracker_ref", "patch", { trackerId, refId });
      this.setState({
        setStateFromTracker: true,
        formParams: fetch.params
      });
    }

    // Update the form parameters
    if (prevProps.trackerId !== trackerId || prevProps.refId !== refId) {
      this.setFormParams();
    }
  }

  setFormParams() {
    let { trackerId, refId } = this.props;
    let fetch = new Traec.Fetch("tracker_ref", "patch", { trackerId, refId });
    this.setState({ formParams: fetch.params });
  }

  setInitFields() {
    let { cref, disciplines, projectReportingPeriods } = this.props;

    // Are we currently on a work package?
    if (!cref) {
      return workPackageDetailsFields;
    }

    let latestCommit = cref.get("latest_commit");
    //console.log("setInitFields 001 latest_commit", latestCommit.toJS());
    //console.log('disciplines', disciplines.toJS())

    let setFields = {};
    Object.assign(setFields, workPackageDetailsFields);

    setFields.name.value = cref.get("name");
    //console.log('set fields 01', setFields);

    setFields.commit__discipline.value = latestCommit.get("discipline");
    if (disciplines) {
      setFields.commit__discipline.options = disciplines
        .map((element, i) => (
          <option key={i} value={element.get("base_uid")}>
            {element.get("name")}
          </option>
        ))
        .toArray();
      // Push on an empty option for null
      setFields.commit__discipline.options.unshift(<option key={-1} value={""} />);
    }
    //
    if (projectReportingPeriods) {
      setFields.commit__reporting_period.value = latestCommit.get("reporting_period");
      // Sort the reporting periods
      let prList = projectReportingPeriods
        .toList()
        .sortBy(i => i.get("startDate"))
        .filter(
          i =>
            i.getInPath("summary.latest_commit") == null ||
            i.getInPath("summary.latest_commit") != latestCommit.get("reporting_period")
        );
      // Format the options
      let currentReportingPeriodId =
        latestCommit?.getInPath("reporting_period_data.uid") || latestCommit?.get("reporting_period") || "";
      setFields.commit__reporting_period.value = currentReportingPeriodId;
      // Set the options
      setFields.commit__reporting_period.options = prList
        .map((item, i) => (
          <option key={i} value={item.get("uid")}>
            {ReportPeriodString(item)}
          </option>
        ))
        .toArray();
      setFields.commit__reporting_period.options.unshift(<option key={-1} value={""} />);
    }

    //
    setFields.commit__due_date.value = latestCommit.get("due_date");

    //console.log('set fields 02', setFields);
    return setFields;
  }

  setPostData(post) {
    // Get the fields that are prefixed with meta_ and put them into the meta_json object
    let postData = {};
    let metaData = {};
    let commitData = {};
    let commitMetaData = {};
    for (let [key, value] of Object.entries(post)) {
      if (key.startsWith("meta_")) {
        let metaKey = key.split("meta_")[1];
        Object.assign(metaData, { [metaKey]: value });
      } else if (key.startsWith("commit__")) {
        let commitKey = key.split("commit__")[1];
        if (commitKey.startsWith("meta_")) {
          Object.assign(commitMetaData, { [commitKey]: value });
        } else {
          Object.assign(commitData, { [commitKey]: value });
        }
      } else {
        Object.assign(postData, { [key]: value });
      }
    }
    Object.assign(commitData, { meta_json: commitMetaData });
    Object.assign(postData, {
      meta_json: metaData,
      latest_commit: commitData
    });
    console.log("DATA", postData, post);
    return postData;
  }

  render() {
    let { company, cref, refId, project, projectId, tracker, rootRef, isRootRef, tenant_meta } = this.props;

    if (!project || !cref) {
      return <Spinner title="Loading..." explanation="" />;
    }

    let rp_term = getTerm("Reporting Package", this.props);

    // Check the User permissions for this project
    return (
      <React.Fragment>
        <h3>{isRootRef ? "Edit Project Details" : `Edit ${rp_term} Details`}</h3>
        <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} />

        <OnHoldButton cref={cref} />

        <div className="row">
          <BSCard
            widthOffset="col-sm-12"
            title={`${rp_term} Details`}
            body={
              <BaseFormConnected
                params={this.state.formParams}
                fields={this.setInitFields()}
                prePostHook={this.setPostData}
                forceShowForm={true}
                hideUnderline={true}
              />
            }
          />
        </div>

        <ErrorBoundary>
          <div className="row">
            <BSCard
              widthOffset="col-sm-12"
              title="Meta data"
              body={
                <SetMetaDataFields
                  saveMetaFetchProps={{
                    handler: "tracker_ref_commit",
                    method: "patch",
                    params: {
                      trackerId: cref.get("tracker"),
                      refId: cref.get("uid"),
                      commitId: cref.getInPath("latest_commit.uid")
                    }
                  }}
                  metaJson={cref.getInPath("latest_commit.meta_json")}
                />
              }
            />
          </div>
        </ErrorBoundary>

        <ProjectPermission projectId={projectId} requiresAdmin={true}>
          <div className="row">
            <WorkPackageList
              project={project}
              tracker={tracker}
              rootRef={rootRef}
              cref={cref}
              showMenu={true}
              tenant_meta={tenant_meta}
            />
          </div>
        </ProjectPermission>

        <div className="row">
          <ErrorBoundary>
            <TechnicalDetails {...this.props} />
          </ErrorBoundary>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { _projectId, _refId } = ownProps.match.params;
  let { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);

  // Now get the project props
  let { company, project, tracker, trackerId, cref, crefId, rootRef, isRootRef } = getProjectProps(
    state,
    projectId,
    refId
  );

  // Don't parse anything until we have everything we need and the refIds match the URL
  if (_refId && !refId) {
    return {
      trackerId,
      projectId: projectId || _projectId,
      refId: refId || _refId
    };
  }

  // Get the disciplines for this work package
  let disciplines = state.getInPath(`entities.projectObjects.byId.${projectId}.disciplines`);

  // Get the reporting periods for this project
  let projectReportingPeriods = crefId
    ? state.getInPath(`entities.projectReportingPeriods.ref.${crefId}.byId.${projectId}`)
    : null;

  let tenant_meta = state.getInPath(`entities.tenant.meta_json`) || Traec.Im.Map();

  return {
    company,
    projectId,
    project,
    tracker,
    trackerId,
    refId,
    isRootRef,
    cref,
    rootRef,
    disciplines,
    projectReportingPeriods,
    tenant_meta
  };
};

export default connect(mapStateToProps)(RefDetails);

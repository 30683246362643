import React, { useState, useEffect } from "react";
import Traec from "traec";

// These components are connected to Redux (for multi-select)
import ActionDropHandle from "./action";
import Grabber, { current_path } from "./grabber.js";

const fetchMove = (trackerId, from_path, to_path, pos) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });
  fetch.updateFetchParams({
    body: {
      type: "MOVE_PATH",
      payload: {
        from_path,
        to_path,
        pos
      }
    },
    headers: { "content-type": "application/json" },
    rawBody: false,
    postSuccessHook: e => window.location.reload()
  });
  fetch.dispatch();
};

function ActionIcons(props) {
  let { show, trackerId, hideActions } = props;
  if (!show) {
    return null;
  }

  let actions = [
    {
      _id: "to_sibling",
      iconName: "arrow-down",
      dropHandler: (from_path, to_path) => {
        console.log(`Handling move from path: ${from_path} to SIBLING of ${to_path}`);
        fetchMove(trackerId, from_path, to_path, "right");
      }
    },
    {
      _id: "to_child",
      iconName: "arrow-right",
      dropHandler: (from_path, to_path) => {
        console.log(`Handling move from path: ${from_path} to CHILD of ${to_path}`);
        fetchMove(trackerId, from_path, to_path, "last-child");
      }
    }
  ];

  let hideActionsSet = new Set(hideActions || []);
  return actions
    .filter((item, i) => !hideActionsSet.has(item._id))
    .map((_props, i) => <ActionDropHandle key={i} {...props} {..._props} />);
}

export function DragDropHandle({ path, trackerId }) {
  let [checkIsOver, setCheckIsOver] = useState(false);
  let [isOver, setIsOver] = useState(false);
  let [lastTimeOver, setLastTimeOver] = useState(null);

  let depth = path.length / 7;

  // Run on componentDidUpdate
  useEffect(() => {
    if (checkIsOver) {
      //console.log(`Checking isOver on: ${path}`)
      let dt = Date.now() - lastTimeOver;
      if (isOver && dt > 50) {
        setIsOver(false);
      }
      setCheckIsOver(false);
    }
  });

  const onDragOver = e => {
    e.preventDefault();
    let _path = current_path[0];
    let _depth = _path.length / 7;
    console.log("onDragOver DragDropHandle", _path);
    if (_depth < 3 && depth >= 3) {
      return null;
    }
    if (!isOver) {
      setIsOver(true);
    }
    setLastTimeOver(Date.now());
  };

  const onDragLeave = e => {
    e.preventDefault();
    //console.log("onDragLeave DragDropHandle", lastTimeOver)
    setTimeout(() => setCheckIsOver(true), 75);
  };

  let _path = current_path[0];
  let _depth = _path.length / 7;
  return (
    <div className="float-left" onDragOver={onDragOver} onDragLeave={onDragLeave}>
      <Grabber show={!isOver} path={path} />
      <ActionIcons
        hideActions={depth < 3 ? (_depth < 3 ? ["to_child"] : ["to_sibling"]) : []}
        show={isOver}
        path={path}
        trackerId={trackerId}
        onDragOverParent={onDragOver}
        onDragLeaveParent={onDragLeave}
        setIsOverParent={setIsOver}
      />
    </div>
  );
}

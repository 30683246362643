import React, { useState } from "react";
import { connect } from "react-redux";
import Traec from "traec";
import Octicon from "react-octicon";
import logger from "redux-logger";

function ActionDropHandle(props) {
  let { path, _id, actionName, iconName, onDragOverParent, setIsOverParent, dropHandler, selected } = props;

  let [isOver, setIsOver] = useState(false);

  const onDragLeave = e => {
    e.preventDefault();
    setIsOver(false);
  };

  const onDragDrop = e => {
    e.preventDefault();
    let from_path = e.dataTransfer.getData("from_path");
    let _selected = (selected || Traec.Im.Set()).add(from_path);
    if (_selected.some(i => path.startsWith(i))) {
      console.warn("Cannot move a path to a child or sibling or itself", _selected.toJS(), path);
      setIsOverParent(false);
      return null;
    }
    let _from_path = _selected.join(",");
    console.log("Handling onDragDrop event with from_path: ", _from_path);
    dropHandler(_from_path, path);
    setIsOverParent(false);
  };

  const onDragOver = e => {
    e.preventDefault();
    //console.log(`onDragOver ${actionName}`)
    if (!isOver) {
      setIsOver(true);
    }
    onDragOverParent(e);
  };

  return (
    <div
      id={`${path}_${_id}`}
      style={{
        display: "inline",
        backgroundColor: isOver ? "#6699ff" : null
      }}
      className="text-center"
      onDragOver={onDragOver}
      onDrop={onDragDrop}
      onDragLeave={onDragLeave}
    >
      <Octicon name={iconName} />
    </div>
  );
}

const mapStateToProps = state => {
  let selected = state.getInPath("ui.dnd") || Traec.Im.Set();
  return { selected };
};

export default connect(mapStateToProps)(ActionDropHandle);

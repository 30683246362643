import React from "react";
import { BSCard } from "traec-react/utils/bootstrap";
import Im from "traec/immutable";
import { ApportionedProjects, getReportingPeriod, SaveButtons } from "AppSrc/project/apportionment/utils";
import { connect } from "react-redux";

const ClientPortions = props => {
  const [state, setState] = React.useState({
    apportionments: Im.List()
  });

  const updateShares = (e, client, index) => {
    e.preventDefault();
    let newState = state.apportionments.size
      ? state.apportionments
      : props.existingApportionments.size
      ? props.existingApportionments
      : Im.List();
    let value = e.target.value / 100;
    newState = newState.set(index, client.set("value", value));
    setState({ ...state, apportionments: newState });
  };

  const addClient = e => {
    let newState = state.apportionments.size
      ? state.apportionments
      : props.existingApportionments.size
      ? props.existingApportionments
      : Im.List();
    newState = newState.push(Im.fromJS({ project: { name: null, uid: null }, ref_id: null, value: null }));
    setState({ ...state, apportionments: newState });
  };

  const removeClient = e => {
    let newState = state.apportionments.size
      ? state.apportionments
      : props.existingApportionments.size
      ? props.existingApportionments
      : Im.List([null]);

    newState = newState.delete(newState.size - 1);
    if (newState.size === 0) {
      newState = Im.List([null]);
    }
    setState({ ...state, apportionments: newState });
  };

  let filteredRequests = props.supplierRequests
    ? props.supplierRequests.filter(request => {
        if (request && request.get("status") === "accepted") {
          return request;
        }
      })
    : Im.List();
  let availableProjects = filteredRequests.valueSeq().map(request => {
    let project = request.get("client_project");
    project = project.set("related_ref", request.get("client_ref"));
    return project;
  });

  let currentApportionment = state.apportionments.size ? state.apportionments : props.existingApportionments;
  let currentReportingPeriod = state.currentReportingPeriod
    ? state.currentReportingPeriod
    : props.currentReportingPeriod;
  return (
    <React.Fragment>
      <BSCard
        title="Client Apportionment"
        body={
          <div>
            <RowHeader projects={currentApportionment} />
            <ApportionedProjects
              key={currentReportingPeriod ? currentReportingPeriod.get("uid") : null}
              projects={currentApportionment}
              onChange={updateShares}
              availableProjects={availableProjects}
              addClient={addClient}
              removeClient={removeClient}
            />
            <SaveButtons
              apportionments={currentApportionment}
              projectId={props.projectId}
              currentReportingPeriod={currentReportingPeriod}
            />
          </div>
        }
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  let { projectId, refId, reportingPeriodId } = ownProps;
  let { reportingPeriods, currentReportingPeriod, currentReportingPeriodId } = getReportingPeriod(
    state,
    reportingPeriodId,
    projectId,
    refId
  );
  let existingApportionments = getExistingApportionments(currentReportingPeriod);

  return {
    projectId,
    refId,
    reportingPeriods,
    currentReportingPeriodId,
    currentReportingPeriod,
    existingApportionments
  };
};

export default connect(mapStateToProps)(ClientPortions);

const RowHeader = props => {
  if (!props.projects.first() || !props.projects.first().get("ref_id")) {
    return null;
  }
  return (
    <div className="row mb-2 mt-1 pt-1 pb-2" style={{ borderBottom: "1px solid lightgrey" }}>
      <div className="col-5">
        <b>Client</b>
      </div>
      <div className="col-5">
        <b>Company Name</b>
      </div>
      <div className="col-2">
        <b>Apportionment %</b>
      </div>
    </div>
  );
};

export const getExistingApportionments = currentReportingPeriod => {
  if (!currentReportingPeriod || !currentReportingPeriod.size) {
    return Im.List();
  }
  try {
    let existing = currentReportingPeriod
      .getIn(["meta_json", "apportionment"])
      .toList()
      .filter(elem => typeof elem === "object");
    return existing;
  } catch (e) {
    return Im.List();
  }
};
